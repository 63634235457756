import classnames from "classnames"

function CompanyImage({ logoURL, width, height, autoSize, neumorphic, zoom }) {
    if (autoSize) width = height = Math.min(Math.max(~~(window.innerWidth / 6.4), 50), 100)

    return (
        <div
            className={classnames("rounded", { neumorphic, zoom })}
            style={{
                backgroundImage: `linear-gradient(145deg, rgba(255, 255, 255, 0.13), rgba(0, 0, 0, 0.13)), url(${logoURL})`,
                width,
                height,
                backgroundSize: width,
            }}
            onError={(e) => {
                e.target.onerror = null
                e.target.src = require("assets/img/icons/utility/no-image.png").default
            }}
        />
    )
}

export default CompanyImage
