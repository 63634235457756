export function toSnakeCase(str) {
    /**
     * Converts a string to a snake_case string.
     * Example: "P/E Ratio" -> "pe_ratio"
     * Example: "Dividend Yield" -> "dividend_yield"
     * */
    return str
        .replace("P/E", "pe") // Replace spaces with underscores
        .replace(/\s+/g, "_") // Replace spaces with underscores
        .toLowerCase() // Convert to lowercase
        .replace(/[^\w\s]/g, "_") // Replace non-alphanumeric characters with underscores
}

export function oneWordToTitleCase(s) {
    /**
     * Converts a single word to a title case string.
     * Example: "dividend" -> "Dividend"
     * */
    return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export function snakeCaseToTitle(s) {
    /**
     * Converts a snake_case string to a title case string.
     * Example: "pe_ratio" -> "P/E Ratio"
     * Example: "dividend_yield" -> "Dividend Yield"
     * */

    return s
        .split("_")
        .map((word) => (word === "pe" ? "P/E" : oneWordToTitleCase(word)))
        .join(" ")
}

export function isStatColumn(columnName) {
    return columnName.includes("yesterday") || columnName.includes("_ago_")
}

export function extractPeriodAndStat(columnName) {
    let period = "yesterday"

    if (columnName.includes("_ago_")) period = `${columnName.split("_ago_")[0]}_ago`

    const stat = columnName.replace(period + "_", "")
    return [period, stat]
}

export function prettyFinancialFigure(value, currencySymbol, decimalPlaces) {
    /**
     * Converts a number to a pretty financial figure string.
     *
     * @param {number} value - The numerical value to be converted.
     * @param {string} [currencySymbol=''] - The currency symbol to be prepended to the output.
     * @param {number} [decimalPlaces=0] - The number of decimal places to include in the output.
     * @returns {string} A string representation of the input value in a human-readable financial format.
     *
     * @example
     * prettyFinancialFigure(1000); // "1K"
     * prettyFinancialFigure(1000000, "$"); // "$1M"
     * prettyFinancialFigure(1230000000, '$', 2); // "$1.23B"
     * prettyFinancialFigure(NaN); // "-"
     */

    if (isNaN(value)) return "-"

    currencySymbol = currencySymbol || ""
    decimalPlaces = decimalPlaces || 0
    if (value < 0) currencySymbol = currencySymbol + "-"

    value = Math.abs(value)

    if (value >= Math.pow(10, 12)) return currencySymbol + (value / Math.pow(10, 12)).toFixed(decimalPlaces) + "T"
    else if (value >= Math.pow(10, 9)) return currencySymbol + (value / Math.pow(10, 9)).toFixed(decimalPlaces) + "B"
    else if (value >= Math.pow(10, 6)) return currencySymbol + (value / Math.pow(10, 6)).toFixed(decimalPlaces) + "M"
    else if (value >= Math.pow(10, 3)) return currencySymbol + (value / Math.pow(10, 3)).toFixed(decimalPlaces) + "K"
    else return currencySymbol + value
}
