// import CompanyFigure from "components/Custom/CompanyFigure";
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { API_URL } from "app"
import { useEffect, useState } from "react"
import Chart from "react-apexcharts"
import { Col, Row } from "reactstrap"
import AlternativeHeader from "components/Headers/AlternativeHeader"
import AuthProvider from "components/Token/AuthProvider"

const GREEN = [16, 242, 116]
const RED = [232, 16, 72]

function Heatmap() {
    let [data, setData] = useState({})
    const [authFetch] = AuthProvider()
    let tickerVarianceLookUp = {}
    let marketCapVarianceLookUp = {}
    let seriesData = []
    let colors = []

    const prettyVariance = (variance) => (variance >= 0 ? `+${variance}%` : `${variance}%`)

    const getColor = (variance) => {
        if (variance >= 0) {
            let value = Math.min(variance, 5.0)
            let alpha = ~~Math.round((value * 100) / 5.0) / 100
            let [r, g, b] = GREEN
            return `rgba(${r}, ${g}, ${b}, ${alpha})`
        } else {
            let value = Math.max(variance, -5.0)
            let alpha = ~~Math.round((value * 100) / -5.0) / 100
            let [r, g, b] = RED
            return `rgba(${r}, ${g}, ${b}, ${alpha})`
        }
    }

    useEffect(() => {
        let url = `${API_URL}/heatmap`
        authFetch(url, {}, "FAILED TO LOAD HEATMAP").then((data) => {
            if (data) {
                setData(data["data"])
            } else setData([])
        })
        // eslint-disable-next-line
    }, [])

    for (let x in data) {
        let { ticker, market_cap, variance } = data[x]
        tickerVarianceLookUp[ticker] = variance
        marketCapVarianceLookUp[market_cap] = variance
        colors.push(getColor(variance))
        seriesData.push({ x: ticker, y: market_cap })
    }

    let state = {
        series: [{ data: seriesData }],
        chart: {
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: false,
                },
            },
        },
        options: {
            legend: {
                show: false,
            },
            dataLabels: {
                enabled: true,
                style: {
                    fontSize: "16px",
                },
                formatter: function (ticker) {
                    return [ticker, prettyVariance(tickerVarianceLookUp[ticker])]
                },
                offsetY: -4,
            },
            colors,
            tooltip: {
                y: {
                    show: true,
                    formatter: function (value) {
                        return prettyVariance(marketCapVarianceLookUp[value])
                    },
                },
            },
            plotOptions: {
                treemap: {
                    distributed: true,
                    enableShades: false,
                },
            },
        },
    }

    return (
        <>
            <AlternativeHeader parentPage="Heatmap" />
            <Row>
                <Col xs={10} className="mx-auto">
                    <div className="mx-auto my-auto">
                        {seriesData.length > 0 ? (
                            <Chart
                                style={{ backgroundColor: "rgb(42,42,42)" }}
                                options={state.options}
                                series={state.series}
                                type="treemap"
                                height={window.innerHeight * 0.8}
                            />
                        ) : (
                            <LoadingData width={420} height={420} />
                        )}
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default Heatmap
