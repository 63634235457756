/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import CalendarView from "views/CalendarView.js"
import CompanyPerformance from "views/dashboards/CompanyPerformance"
import MarketMovements from "views/dashboards/MarketMovements.js"
import SectorPerformance from "views/dashboards/SectorPerformance"
import PriceChanges from "views/dashboards/PriceChanges"
import Explore from "views/Explore"
import Favorites from "views/Favorites"
import Heatmap from "views/Heatmap"

const routes = [
    {
        collapse: true,
        name: "Dashboards",
        icon: "ni ni-chart-bar-32 text-info",
        state: "dashboardsCollapse",
        views: [
            {
                path: "/market-movements",
                name: "Market Movements",
                miniName: "M",
                component: MarketMovements,
                layout: "/admin",
            },
            {
                path: "/company-dashboard",
                name: "Company Dashboard",
                miniName: "C",
                component: CompanyPerformance,
                layout: "/admin",
            },
            {
                path: "/sector-performance",
                name: "Sector Performance",
                miniName: "S",
                component: SectorPerformance,
                layout: "/admin",
            },
            {
                path: "/price-changes",
                name: "Price Changes",
                miniName: "P",
                component: PriceChanges,
                layout: "/admin",
            },
        ],
    },
    {
        path: "/heatmap",
        name: "Heatmap",
        icon: "ni ni ni-chart-pie-35 text-green",
        // icon: "tim-icons icon-components text-green",
        component: Heatmap,
        layout: "/admin",
    },
    {
        path: "/favorites",
        name: "Favorites",
        icon: "tim-icons icon-shape-star text-yellow",
        component: Favorites,
        layout: "/admin",
    },
    {
        path: "/explore",
        name: "Explore",
        icon: "ni ni-diamond text-primary",
        component: Explore,
        layout: "/admin",
    },
    {
        path: "/calendars",
        name: "Calendars",
        icon: "ni ni-calendar-grid-58 text-red",
        component: CalendarView,
        layout: "/admin",
    },
]

export default routes
