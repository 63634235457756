import { Col, Input, Row } from "reactstrap"
import { FormGroup, InputGroupAddon, InputGroupText, InputGroup } from "reactstrap"
import React from "react"

const FIELDS = ["col_name", "period", "operator", "value"]

function QueryFilterRow({ rowData, rowIndex, filterOptions, periods, operators, updateFunc, removeFunc }) {
    let columnName = rowData.col_name
    let filterCol = filterOptions[columnName]

    const getOptions = (field) => {
        if (field === "col_name") {
            return Object.entries(filterOptions).map((option, option_index) => (
                <option key={option_index} value={option[0]}>
                    {option[1]["name"]}
                </option>
            ))
        } else if (field === "period") {
            return periods.map((period, period_index) => (
                <option key={period_index} value={period[0]}>
                    {period[1]}
                </option>
            ))
        } else if (field === "operator") {
            return (
                filterCol?.type &&
                operators[filterCol.type].map((option, option_index) => (
                    <option key={option_index} value={option[0]}>
                        {option[1]}
                    </option>
                ))
            )
        } else if (field === "value") {
            if (filterCol?.type === "categorical")
                return filterCol["data"].map((elem, elem_index) => (
                    <option key={elem_index} value={elem}>
                        {elem}
                    </option>
                ))
            else if (filterCol?.type === "numerical")
                return (
                    <Input
                        type="text"
                        value={rowData[field] ?? "none"}
                        onChange={(e) => {
                            rowData[field] = e.target.value
                            updateFunc(rowIndex, rowData)
                        }}
                    />
                )
            else if (filterCol?.type === "trait")
                return ["true", "false"].map((elem, elem_index) => (
                    <option key={elem_index} value={elem}>
                        {elem}
                    </option>
                ))
            return <option>NOT SUPPORTED</option>
        } else {
            return <option>Invalid</option>
        }
    }

    const getInput = (field) => {
        if (field === "value" && ["numerical", "stat"].includes(filterCol?.type)) {
            if (columnName === "market_cap_usd")
                return (
                    <FormGroup className="mb-0">
                        <InputGroup>
                            <InputGroupAddon addonType="prepend">
                                <InputGroupText>$</InputGroupText>
                            </InputGroupAddon>
                            <Input
                                type="text"
                                value={rowData[field] ?? "none"}
                                onChange={(e) => {
                                    rowData[field] = e.target.value
                                    updateFunc(rowIndex, rowData)
                                }}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>Billion</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                )
            else if (filterCol?.type === "stat" || columnName.includes("yield"))
                return (
                    <FormGroup className="mb-0">
                        <InputGroup>
                            <Input
                                type="text"
                                value={rowData[field] ?? "none"}
                                onChange={(e) => {
                                    rowData[field] = e.target.value
                                    updateFunc(rowIndex, rowData)
                                }}
                            />
                            <InputGroupAddon addonType="append">
                                <InputGroupText>%</InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>
                    </FormGroup>
                )
            else
                return (
                    <Input
                        type="text"
                        value={rowData[field] ?? "none"}
                        onChange={(e) => {
                            rowData[field] = e.target.value
                            updateFunc(rowIndex, rowData)
                        }}
                    />
                )
        } else {
            return (
                <Input
                    type="select"
                    value={rowData[field] ?? "none"}
                    onChange={(e) => {
                        let oldFieldType = filterOptions[rowData.col_name]?.type
                        let newFieldType = filterOptions[e.target.value]?.type
                        let areDifferentFieldTypes =
                            ["numerical", "stat"].includes(oldFieldType) !== ["numerical", "stat"].includes(newFieldType)

                        if (field === "col_name" && areDifferentFieldTypes) {
                            rowData.operator = "none"
                            rowData.value = ""
                        }
                        rowData[field] = e.target.value
                        updateFunc(rowIndex, rowData)
                    }}
                >
                    <option value="none" disabled hidden />
                    {getOptions(field)}
                </Input>
            )
        }
    }

    return (
        <>
            {/* Horizontal Separator Line */}
            {rowIndex > 0 && <hr className="my-1" color="#CCC" width="230px" />}
            <Row className="my-3">
                {/* Filter Options */}
                <Col xs={10}>
                    <Row>
                        {FIELDS.map(
                            (field, field_index) =>
                                field in rowData && (
                                    <Col xs={rowData.period ? 3 : 4} key={field_index}>
                                        {getInput(field)}
                                    </Col>
                                )
                        )}
                    </Row>
                </Col>
                {/* Remove Button */}
                <Col className="ml-auto" xs={2}>
                    <img
                        alt="remove"
                        className="ml-1 mt-2"
                        width={24}
                        src={require("assets/img/icons/utility/remove_circle.svg").default}
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            removeFunc(rowIndex)
                        }}
                    />
                </Col>
            </Row>
        </>
    )
}

export default QueryFilterRow
