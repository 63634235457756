export const marketStatesNames = {
    POSTPOST: "After Hours",
    POST: "After Hours",
    PREPRE: "Closed Market",
    PRE: "Pre Market",
    REGULAR: "Open Market",
    CLOSED: "Closed Market",
}

export const gradesMap = {
    Buy: "bg-green",
    Positive: "bg-green",
    Outperform: "bg-green",
    Overweight: "bg-green",
    "Strong Buy": "bg-green",
    "Market Outperform": "bg-green",
    "Sector Outperform": "bg-green",
    Hold: "bg-yellow",
    Neutral: "bg-yellow",
    Perform: "bg-yellow",
    "In-Line": "bg-yellow",
    "Market Perform": "bg-yellow",
    "Sector Perform": "bg-yellow",
    "Equal-weight": "bg-yellow",
    "Equal-Weight": "bg-yellow",
    Sell: "bg-red",
    Underweight: "bg-red",
    Negative: "bg-red",
    Underperform: "bg-red",
    "Market Underperform": "bg-red",
    "Sector Underperform": "bg-red",
}

export const chartPeriods = ["1D", "1W", "1M", "3M", "6M", "1Y", "2Y", "5Y", "10Y", "All"]
