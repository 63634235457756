import { chartOptions, parseOptions } from "variables/charts"
import Chart from "chart.js"
import { Bar } from "react-chartjs-2"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { prettyFinancialFigure } from "utils/strings"

const DOCUMENT_COLUMNS = {
    income_statement: ["Total Revenue", "Gross Profit", "Operating Income", "Net Income"],
    balance_sheet: ["Total Assets", "Total Liabilities", "Total Debt", "Stockholders Equity"],
    cash_flow: ["Operating Cash Flow", "Free Cash Flow", "Investing Cash Flow", "Financing Cash Flow"],
}

const columnColors = ["rgb(65, 105, 225)", "rgb(255,166,0)", "rgb(50, 205, 50)", "rgb(178,36,85)"]

function transformDataForChart(data, documentType) {
    // Initialize arrays to hold the labels and a map to hold the datasets
    const labels = []
    const datasetsMap = {}

    let columns = DOCUMENT_COLUMNS[documentType]
    // Iterate over each key (quarter/year) in the data object
    for (const [period, financials] of Object.entries(data)) {
        // Add the period to the labels array
        labels.push(period)
        // Iterate over each financial metric within the period
        let colorIndex = 0
        for (const metric of columns)
            if (Object.keys(financials).includes(metric)) {
                // If the dataset for this metric doesn't exist and the metric exists in the data, create it
                if (!datasetsMap[metric])
                    datasetsMap[metric] = {
                        label: metric,
                        data: [],
                        // Add more properties for styling as needed
                        backgroundColor: columnColors[colorIndex],
                    }
                // Add the value to the appropriate dataset
                datasetsMap[metric].data.push(financials[metric])
                colorIndex += 1
            }
    }

    // Convert the datasets map into an array of dataset objects
    const chartDatasets = Object.values(datasetsMap)

    // Return the transformed data
    return {
        labels,
        datasets: chartDatasets,
    }
}

function FinancialsBarChart({ chartData, documentType, currencySymbol, height, width }) {
    if (chartData === null || chartData === undefined) return <LoadingData />
    else if (Object.keys(chartData).length === 0)
        return (
            <h1 className="mx-auto text-center" style={{ height, width }}>
                Oops Nothing Found
            </h1>
        )

    if (window.Chart) parseOptions(Chart, chartOptions())

    let renderData = transformDataForChart(chartData, documentType)

    currencySymbol = currencySymbol || ""

    const options = {
        scales: {
            yAxes: [
                {
                    ticks: {
                        callback: function (label) {
                            return prettyFinancialFigure(label, currencySymbol)
                        },
                    },
                    scaleLabel: {
                        display: true,
                    },
                },
            ],
        },
    }

    return (
        <div className="chart-area" style={{ position: "relative", height: height || 420, width: width || 700 }}>
            <Bar
                data={renderData}
                id="financials-chart"
                className="chart-canvas"
                options={options}
                legend={{
                    display: true,
                }}
            />
        </div>
    )
}

export default FinancialsBarChart
