import fetch from "node-fetch"
import { useContext } from "react"
import { UserContext } from "components/Contexts/UserContext"
import handle_http_error from "utils/errors"
import { useHistory } from "react-router-dom"

function AuthProvider() {
    const { accessToken, setAccessToken, setIsLoggedIn, setRedirectPath } = useContext(UserContext)
    const history = useHistory()
    const abortController = new AbortController()

    function authFetch(url, payload, errorMessage) {
        const headers = accessToken ? { headers: { Authorization: `Bearer ${accessToken}` } } : {}
        const signal = { signal: abortController.signal }

        const defaultFields = { ...headers, ...signal }

        if (payload) payload = { ...defaultFields, ...payload }
        else payload = defaultFields

        return fetch(url, payload)
            .then((response) => {
                if (response.status === 401 || response.status === 422) {
                    setAccessToken(null)
                    setIsLoggedIn(false)
                    if (window.location.pathname === "/login") setRedirectPath(null)
                    else {
                        setRedirectPath(window.location.pathname)
                        history.push("/login")
                    }
                    return null
                }
                return response.json()
            })
            .then((data) => {
                if (data.accessToken) setAccessToken(data.accessToken)
                return data
            })
            .catch((error) => {
                handle_http_error(error)
                if (errorMessage) console.error(errorMessage)
            })
    }

    const abort = () => {
        abortController.abort()
    }

    return [authFetch, abort]
}

export default AuthProvider
