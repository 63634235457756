/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useState } from "react"
// nodejs library that concatenates classes
import classnames from "classnames"
// reactstrap components
import {
    Button,
    Card,
    CardFooter,
    CardBody,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Container,
    Row,
    Col,
} from "reactstrap"
import handle_http_error from "utils/errors"
import { API_URL } from "app"
// core components
import AuthHeader from "components/Headers/AuthHeader.js"
import { UserContext } from "components/Contexts/UserContext"
import fetch from "node-fetch"
import { useHistory } from "react-router-dom"
import { useContext, useMemo } from "react"

const notificationAlertOptions = {
    place: "tr",
    message: (
        <div className="alert-text">
            <span className="alert-title" data-notify="title">
                Failed To Login
            </span>
        </div>
    ),
    type: "danger",
    icon: "ni ni-bell-55",
    autoDismiss: 2.3,
}

function Login() {
    const { setUser, setAccessToken, setIsLoggedIn, redirectPath, setRedirectPath, notifyRef } = useContext(UserContext)
    const [focusedEmail, setFocusedEmail] = useState(false)
    const [focusedPassword, setFocusedPassword] = useState(false)

    const [email, setEmail] = useState(null)
    const [password, setPassword] = useState(null)

    const history = useHistory()

    const handleSubmit = () => {
        let url = `${API_URL}/create_token`
        fetch(url, { method: "POST", body: JSON.stringify({ email, password }) })
            .then((data) => {
                if (Math.floor(data.status / 100) === 4) throw Error
                return data.json()
            })
            .then((result) => {
                setUser(result["user"])
                setAccessToken(result["accessToken"])
                setIsLoggedIn(true)
                history.push(redirectPath || "/market-movements")
                setRedirectPath(null)
            })
            .catch((error) => {
                handle_http_error(error)
                notifyRef.current.notificationAlert(notificationAlertOptions)
            })
    }

    const thirdPartyLogin = useMemo(() => {
        return [
            {
                name: "Google",
                icon: "google",
                url: `${API_URL}/google_login`,
            },
            {
                name: "Facebook",
                icon: "facebook",
                url: `${API_URL}/facebook_login`,
            },
            {
                name: "Apple",
                icon: "apple",
                url: `${API_URL}/apple_login`,
            },
        ]
    }, [])

    return (
        <>
            <AuthHeader title="Welcome to Make Life Eazy!" lead="Please login so you can access your dashboard" />
            <Container className="mt--8 pb-5">
                <Row className="justify-content-center">
                    <Col xl={6} lg={7} md={9} sm={12}>
                        <Card className="border-0 mb-0 frosted-glass">
                            <CardBody className="px-lg-5 pt-5">
                                <div className="text-center  mb-4">
                                    <label>Sign in with credentials</label>
                                </div>
                                <Form role="form">
                                    <FormGroup
                                        className={classnames("mb-3", {
                                            focused: focusedEmail,
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-email-83" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                style={{ fontSize: 16 }}
                                                placeholder="Email"
                                                type="email"
                                                onFocus={() => setFocusedEmail(true)}
                                                onBlur={() => setFocusedEmail(true)}
                                                onChange={(event) => setEmail(event.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <FormGroup
                                        className={classnames({
                                            focused: focusedPassword,
                                        })}
                                    >
                                        <InputGroup className="input-group-merge input-group-alternative">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="ni ni-lock-circle-open" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                style={{ fontSize: 16 }}
                                                placeholder="Password"
                                                type="password"
                                                onFocus={() => setFocusedPassword(true)}
                                                onBlur={() => setFocusedPassword(true)}
                                                onChange={(event) => setPassword(event.target.value)}
                                            />
                                        </InputGroup>
                                    </FormGroup>
                                    <div className="text-center">
                                        <Button className="mt-4" color="primary" type="button" onClick={handleSubmit}>
                                            Sign in
                                        </Button>
                                    </div>
                                </Form>
                            </CardBody>
                            <CardFooter className="bg-transparent pb-5">
                                <div className="text-dark text-center mt-2 mb-1">
                                    <small>Or sign in with</small>
                                </div>
                                <Row className="text-center">
                                    {thirdPartyLogin.map((login, index) => {
                                        return (
                                            <Col xs={12} lg={4} key={index} className="mt-2">
                                                <Button
                                                    className="btn-neutral btn-icon"
                                                    color="default"
                                                    href="#pablo"
                                                    block={window.innerWidth < 992}
                                                    onClick={(e) => e.preventDefault()}
                                                >
                                                    <span className="btn-inner--icon">
                                                        <img
                                                            alt={login.name}
                                                            src={require(`assets/img/icons/common/${login.icon}.svg`).default}
                                                        />
                                                    </span>
                                                    <span className="btn-inner--text">{login.name}</span>
                                                </Button>
                                            </Col>
                                        )
                                    })}
                                </Row>
                            </CardFooter>
                        </Card>
                        <Row className="mt-3">
                            <Col xs="6">
                                <a className="text-dark" href="#pablo" onClick={(e) => e.preventDefault()}>
                                    <small>Forgot password?</small>
                                </a>
                            </Col>
                            <Col className="text-right" xs="6">
                                <a className="text-dark" href="/register">
                                    <small>Create new account</small>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default Login
