import { Card } from "reactstrap"

// react component for creating dynamic tables
import BootstrapTable from "react-bootstrap-table-next"
import paginationFactory from "react-bootstrap-table2-paginator"
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit"
import filterFactory, { selectFilter } from "react-bootstrap-table2-filter"
import { useEffect, useState } from "react"

import { API_URL } from "app"
import CompanyFigure from "components/Custom/CompanyFigure"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import AuthProvider from "components/Token/AuthProvider"
import AlternativeHeader from "components/Headers/AlternativeHeader"
import FavoritesStar from "components/Custom/FavoritesStar"

const pagination = paginationFactory({
    page: 1,
    alwaysShowAllBtns: false,
    showTotal: true,
    withFirstAndLast: true,
    sizePerPageRenderer: ({ options, currSizePerPage, onSizePerPageChange }) => (
        <div className="dataTables_length" id="datatable-basic_length">
            <label>
                Show{" "}
                {
                    <select
                        name="datatable-basic_length"
                        aria-controls="datatable-basic"
                        className="form-control form-control-sm"
                        onChange={(e) => onSizePerPageChange(e.target.value)}
                    >
                        <option value="10">10</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                    </select>
                }{" "}
                entries.
            </label>
        </div>
    ),
})

const { SearchBar } = Search

function Favorites() {
    let [favoritesData, setFavoritesData] = useState([])
    let [sectorOptions, setSectorOptions] = useState({})
    let [industryOptions, setIndustryOptions] = useState({})
    const [authFetch] = AuthProvider()

    useEffect(() => {
        let url = `${API_URL}/favorites`
        authFetch(url, {}, `FAILED TO LOAD DATA`).then((data) => {
            if (data) {
                setFavoritesData(data["favorites"])
                let tempSectorOptions = {}
                let tempIndustryOptions = {}
                for (let x of data["favorites"]) {
                    tempIndustryOptions[x.industry] = x.industry
                    tempSectorOptions[x.sector] = x.sector
                }
                setSectorOptions(tempSectorOptions)
                setIndustryOptions(tempIndustryOptions)
            }
        })
        // eslint-disable-next-line
    }, [])

    return (
        <>
            <AlternativeHeader parentPage="Favorites" />

            <Card className="m-lg-5 m-3">
                {favoritesData.length > 0 ? (
                    <ToolkitProvider
                        data={favoritesData}
                        keyField="name"
                        columns={[
                            {
                                text: "Favorite",
                                sort: false,
                                style: {
                                    width: 40,
                                },
                                formatter: (cell, row) => <FavoritesStar ticker={row.ticker} favorite={true} />,
                            },
                            {
                                dataField: "ticker",
                                text: "Ticker",
                                formatter: (cell, row) => (
                                    <CompanyFigure
                                        width={50}
                                        height={50}
                                        alt={cell}
                                        neumorphic={true}
                                        hideTicker={false}
                                        ticker={cell}
                                        logoURL={row.logo_url}
                                        extraClasses="mb-0"
                                        textStyle={{
                                            fontSize: "14px",
                                            fontWeight: "bold",
                                            color: "#32325d",
                                        }}
                                    />
                                ),
                                sort: true,
                                searchable: true,
                                style: {
                                    width: 240,
                                },
                            },
                            {
                                dataField: "name",
                                text: "Name",
                                sort: true,
                                formatter: (cell) => <h3>{cell}</h3>,
                            },
                            {
                                dataField: "sector",
                                text: "Sector",
                                sort: true,
                                formatter: (cell) => <h3>{cell}</h3>,
                                filter: selectFilter({
                                    options: sectorOptions,
                                }),
                            },
                            {
                                dataField: "industry",
                                text: "Industry",
                                sort: true,
                                formatter: (cell) => <h3>{cell}</h3>,
                                filter: selectFilter({
                                    options: industryOptions,
                                }),
                            },
                        ]}
                        search
                    >
                        {(props) => (
                            <div className="py-4">
                                <div id="datatable-basic_filter" className="dataTables_filter px-4 pb-1">
                                    <label>
                                        Search:
                                        <SearchBar className="form-control-sm" placeholder="" {...props.searchProps} />
                                    </label>
                                </div>
                                <BootstrapTable
                                    {...props.baseProps}
                                    bootstrap4={true}
                                    pagination={pagination}
                                    bordered={false}
                                    defaultSorted={[{ dataField: "ticker", order: "asc" }]}
                                    filter={filterFactory()}
                                />
                            </div>
                        )}
                    </ToolkitProvider>
                ) : (
                    <LoadingData />
                )}
            </Card>
        </>
    )
}

export default Favorites
