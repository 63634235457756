import { Col, ListGroupItem, Row } from "reactstrap"

function CompanyTableRow({ stat_name, value }) {
    return (
        <>
            <ListGroupItem className="px-1 py-1">
                <Row className="align-items-center">
                    <Col>
                        <p className="mt-0 mb-0 text-sm">
                            <span className="text-nowrap">{stat_name}</span>
                        </p>
                    </Col>
                    <Col className="p-0 text-right">
                        <p className="px-0 mt-0 mb-0 text-sm">
                            {value !== null ? (
                                <span className={value >= 0 ? (value === 0 ? "text-info mr-2" : "text-success mr-2") : "text-danger mr-2"}>
                                    <span>
                                        <i
                                            className={
                                                value >= 0 ? (value === 0 ? "fa fa-arrow-right" : "fa fa-arrow-up") : "fa fa-arrow-down"
                                            }
                                        />{" "}
                                        {value}%
                                    </span>
                                </span>
                            ) : (
                                <span className="text-gray-dark mr-2">N/A</span>
                            )}
                        </p>
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    )
}

export default CompanyTableRow
