function StockDateField({ eventName, dateOfEvent, daysTillEvent }) {
    return (
        <p className="mb-1">
            <span className="stat-label">{eventName}: </span>
            <span>{dateOfEvent}</span>
            {daysTillEvent < 2 ? <span> {daysTillEvent === 0 ? "(Today)" : "(Tomorrow)"}</span> : <span> ({daysTillEvent} Days)</span>}
        </p>
    )
}

export default StockDateField
