import { Button, Col } from "reactstrap"

function CardControls({ tableIndex, buttonsConfig }) {
    return (
        <Col className="text-right px-0">
            {buttonsConfig.map((button, index) => (
                <Button
                    className={`btn-${button.color}`}
                    color={button.color}
                    href="#pablo"
                    onClick={() => button.action(tableIndex)}
                    key={index}
                    size="sm"
                >
                    {button.text}
                </Button>
            ))}
        </Col>
    )
}

export default CardControls
