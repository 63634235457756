import { createContext, useState } from "react"

export const MarketMovementModalContext = createContext({
    modalData: {},
    setModalData: () => null,
    modalMode: "new",
    setModalMode: () => null,
    tableIndex: null,
    setTableIndex: () => null,
})

export const MarketMovementModalProvider = ({ children }) => {
    const [modalData, setModalData] = useState({})
    const [modalMode, setModalMode] = useState("new")
    const [tableIndex, setTableIndex] = useState(null)
    const value = {
        modalData,
        setModalData,
        modalMode,
        setModalMode,
        tableIndex,
        setTableIndex,
    }

    return <MarketMovementModalContext.Provider value={value}>{children}</MarketMovementModalContext.Provider>
}
