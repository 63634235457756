import { Col, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { snakeCaseToTitle, prettyFinancialFigure, oneWordToTitleCase } from "utils/strings"

const OPERATOR_SYMBOL_MAP = {
    equal: "=",
    not_equal: "≠",
    greater_than: ">",
    less_than: "<",
    greater_than_or_equal: "≥",
    less_than_or_equal: "≤",
    is: "is",
    in: "in",
    not_in: "not in",
}

function QueryInfoDropDown({ queryData }) {
    let infoOutlineIcon = require("assets/img/icons/utility/info_outline.svg").default
    let infoIcon = require("assets/img/icons/utility/info.svg").default

    const dropDownWidth = Math.min(500, window.innerWidth * 0.9)
    const dropDownTitle = `${oneWordToTitleCase(queryData.direction)} ${queryData.count} Sorted By ${snakeCaseToTitle(queryData.sorting_col)}`

    const parseQueryData = (data) =>
        data.conditions.map((prop, key) => {
            let value = prop.value
            if (prop.col_name.includes("yesterday") || prop.col_name.includes("ago") || prop.col_name.includes("yield"))
                value = `${prop.value}%`
            else if (prop.col_name.includes("market_cap")) value = `$${prettyFinancialFigure(prop.value)}`
            return (
                <li className="ml--3" style={{ textAlign: "left", fontSize: 16 }} key={key}>
                    {`${snakeCaseToTitle(prop.col_name)} ${OPERATOR_SYMBOL_MAP[prop.operator]} ${value}`}
                </li>
            )
        })

    return (
        <Col xs={2} md={1} className="text-right pb-3">
            <UncontrolledDropdown nav>
                <DropdownToggle tag="a">
                    <img
                        alt="info"
                        className=""
                        width={28}
                        src={infoOutlineIcon}
                        style={{ cursor: "pointer" }}
                        onMouseOver={(e) => (e.currentTarget.src = infoIcon)}
                        onMouseLeave={(e) => (e.currentTarget.src = infoOutlineIcon)}
                    />
                </DropdownToggle>
                <DropdownMenu right className="dark-frosted-glass" style={{ minWidth: dropDownWidth, color: "white" }}>
                    <p className="text-center mt-1" style={{ fontSize: 16, fontWeight: "bold" }}>
                        {dropDownTitle}
                    </p>
                    <ul className="ml-2">{parseQueryData(queryData)}</ul>
                </DropdownMenu>
            </UncontrolledDropdown>
        </Col>
    )
}

export default QueryInfoDropDown
