import { chartOptions, parseOptions } from "variables/charts"
import { colors } from "variables/colors"
import Chart from "chart.js"
import { Line } from "react-chartjs-2"

const X_AXIS_DATE_FORMAT_MAP = {
    "1D": { hour: "2-digit" },
    "1W": { day: "2-digit" },
    "1M": { day: "2-digit", month: "short" },
    "3M": { month: "short" },
    "6M": { month: "short" },
    "1Y": { month: "short", year: "numeric" },
    "2Y": { month: "short", year: "numeric" },
    "5Y": { year: "numeric" },
    "10Y": { year: "numeric" },
    All: { year: "numeric" },
}

const CHART_DATE_FORMAT_MAP = {
    "1D": { minute: "2-digit", hour: "2-digit" },
    "1W": { minute: "2-digit", hour: "2-digit", day: "2-digit", month: "short" },
    "1M": { day: "2-digit", month: "short" },
    "3M": { day: "2-digit", month: "short" },
    "6M": { day: "2-digit", month: "short" },
    "1Y": { day: "2-digit", month: "short", year: "numeric" },
    "2Y": { day: "2-digit", month: "short", year: "numeric" },
    "5Y": { day: "2-digit", month: "short", year: "numeric" },
    "10Y": { day: "2-digit", month: "short", year: "numeric" },
    All: { day: "2-digit", month: "short", year: "numeric" },
}

const X_AXIS_TICKS_LIMIT_MAP = {
    "1D": 10,
    "1W": 5,
    "1M": 5,
    "3M": 3,
    "6M": 6,
    "1Y": 4,
    "2Y": 8,
    "5Y": 5,
    "10Y": 10,
    All: 10,
}

function StockChart({ chartData, period, timeZone, height }) {
    if (window.Chart) parseOptions(Chart, chartOptions())

    const data = (canvas) => {
        const ctx = canvas.getContext("2d")
        const gradient = ctx.createLinearGradient(0, 0, 0, height || 500)

        if (chartData.data[0] < chartData.data[chartData.data.length - 1]) {
            gradient.addColorStop(0, "rgba(20, 176, 60, 0.7)")
            gradient.addColorStop(1, "rgba(20, 176, 60, 0.3)")
        } else {
            gradient.addColorStop(0, "rgba(162, 22, 64, 0.7)")
            gradient.addColorStop(1, "rgba(162, 22, 64, 0.3)")
        }

        return {
            labels: chartData.labels,
            datasets: [
                {
                    backgroundColor: gradient,
                    borderColor: chartData.data[0] < chartData.data[chartData.data.length - 1] ? "rgb(51, 215, 96)" : "rgb(204, 29, 70)",
                    borderWidth: 2,
                    data: chartData.data,
                },
            ],
        }
    }

    let xAxisDatetimeFormat = X_AXIS_DATE_FORMAT_MAP[period] || X_AXIS_DATE_FORMAT_MAP["1M"]
    let chartDatetimeFormat = CHART_DATE_FORMAT_MAP[period] || CHART_DATE_FORMAT_MAP["1M"]
    let ticksLimit = X_AXIS_TICKS_LIMIT_MAP[period] || X_AXIS_TICKS_LIMIT_MAP["1M"]

    xAxisDatetimeFormat["timeZone"] = chartDatetimeFormat["timeZone"] = timeZone || "America/New_York"

    let lineChartOptions = {
        responsive: true,
        tooltips: {
            callbacks: {
                title: function (x) {
                    let isoDatetimeString = x[0].label
                    let datetimeObject = new Date(isoDatetimeString)
                    return datetimeObject.toLocaleString("en-AU", chartDatetimeFormat)
                },
            },
        },
        scales: {
            yAxes: [
                {
                    gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[200],
                    },
                },
            ],
            xAxes: [
                {
                    gridLines: {
                        color: colors.gray[200],
                        zeroLineColor: colors.gray[200],
                    },
                    ticks: {
                        maxTicksLimit: ticksLimit,
                        callback: function (isoDatetimeString, index) {
                            let datetimeObject = new Date(isoDatetimeString)
                            return datetimeObject.toLocaleString("en-AU", xAxisDatetimeFormat)
                        },
                    },
                },
            ],
        },
    }

    return (
        <div className="chart-area" style={{ position: "relative", height: height || 500 }}>
            <Line data={data} options={lineChartOptions} id="stock-chart" className="chart-canvas" />
        </div>
    )
}

export default StockChart
