/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
// reactstrap components
import {
    Container,
    Row,
    Col,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    FormGroup,
    InputGroup,
    Input,
} from "reactstrap"
// core components
import { snakeCaseToTitle } from "utils/strings"
import AuthProvider from "components/Token/AuthProvider"
import AlternativeHeader from "components/Headers/AlternativeHeader.js"
import StockPrices from "components/Custom/StockPrices"
import { API_URL } from "app"

export default function PriceChanges() {
    const [authFetch] = AuthProvider()
    const [dashboardData, setDashboardData] = useState(null)
    const [sortingField, setSortingField] = useState("price_change")
    const [sortingDirection, setSortingDirection] = useState("desc")
    const [searchText, setSearchText] = useState("")

    const refreshDashboardData = () => {
        setDashboardData(null)
        let url = `${API_URL}/price-changes-dashboard`
        let payload = { method: "GET" }
        authFetch(url, payload, "FAILED TO GET PRICE CHANGES DATA").then((result) => {
            if (result) setDashboardData(result["data"])
            else setDashboardData(null)
        })
    }

    const applySearch = (event) => {
        let searchFieldText = event.target.value.toLocaleLowerCase()
        setSearchText(searchFieldText)
    }

    let filteredList = dashboardData?.filter(
        (element) => element.ticker.toLocaleLowerCase().includes(searchText) || element.name.toLocaleLowerCase().includes(searchText)
    )

    useEffect(() => {
        refreshDashboardData()
        // eslint-disable-next-line
    }, [])

    const sortingFields = ["price_change", "market_cap", "sector"]

    return (
        <>
            <AlternativeHeader parentPage="Dashboards" childPage="Price Changes" />
            <Container fluid>
                {/***************  Search Bar   ***************/}
                <Row>
                    <Col md="12" lg="6" xl="4" className="mx-auto">
                        <FormGroup>
                            <InputGroup className="mb-3">
                                <Input
                                    className="rounded"
                                    placeholder="search..."
                                    type="search"
                                    style={{ fontSize: 16 }}
                                    onChange={applySearch}
                                    value={searchText}
                                />
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                {/***************  Sorting Controls   ***************/}
                <Row className="mb-4">
                    <Col className="text-center mb">
                        {/***************  Index Filter   *****************/}
                        <span className="font-weight-bold mr-2">Sorting Field: </span>
                        <UncontrolledDropdown>
                            <DropdownToggle caret color="secondary">
                                {snakeCaseToTitle(sortingField)}
                            </DropdownToggle>
                            <DropdownMenu>
                                {sortingFields.map((prop, key) => {
                                    return (
                                        <DropdownItem href="#pablo" onClick={() => setSortingField(prop)} key={key + 1}>
                                            {snakeCaseToTitle(prop)}
                                        </DropdownItem>
                                    )
                                })}
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        {/***************  Direction   *****************/}
                        <img
                            alt="sorting_icon"
                            src={require(`assets/img/icons/utility/sort-${sortingDirection}-icon.svg`).default}
                            onClick={() => {
                                if (sortingDirection === "asc") setSortingDirection("desc")
                                else setSortingDirection("asc")
                            }}
                            style={{ cursor: "pointer" }}
                            width={32}
                        />
                    </Col>
                </Row>
                {/***************  Dashboard Data   ***************/}
                <StockPrices data={filteredList} sortingField={sortingField} sortingDirection={sortingDirection} />
            </Container>
        </>
    )
}
