import { chartOptions, parseOptions } from "variables/charts"
import Chart from "chart.js"
import { Doughnut } from "react-chartjs-2"

const gradeColors = {
    "Strong Buy": "rgb(13,145,56)",
    Buy: "rgb(51,215,96)",
    Hold: "rgb(255, 205, 86)",
    Sell: "rgb(204,29,70)",
    "Strong Sell": "rgb(140,8,43)",
}

function StockChart({ chartData, height }) {
    if (window.Chart) parseOptions(Chart, chartOptions())

    let labels = []
    let values = []
    let chartColors = []

    Object.entries(chartData).forEach(([k, v]) => {
        labels.push(k)
        values.push(v)
        chartColors.push(gradeColors[k])
    })

    const renderData = {
        labels: labels,
        datasets: [
            {
                label: "Buy/Sell Counts",
                data: values,
                backgroundColor: chartColors,
                hoverOffset: 4,
            },
        ],
    }

    return (
        <div className="chart-area" style={{ position: "relative", height: height || 420 }}>
            <Doughnut
                data={renderData}
                id="stock-chart"
                className="chart-canvas"
                legend={{
                    display: true,
                }}
            />
        </div>
    )
}

export default StockChart
