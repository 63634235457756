import classnames from "classnames"
import React, { useState, useEffect, useContext, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Card, CardBody, Row, Badge, Col, Button, Nav, NavLink, NavItem, TabPane, TabContent, Table, ListGroup } from "reactstrap"

import AlternativeHeader from "components/Headers/AlternativeHeader"
import BusinessSummaryTab from "components/Custom/StockInfo/BusinessSummaryTab"
import BuySellDoughnut from "components/Custom/Charts/BuySellDoughnut"
import CompanyTableRow from "components/Custom/CompanyTableRow"
import CompanyImage from "components/Custom/CompanyImage"
import FavoritesStar from "components/Custom/FavoritesStar"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import StockChart from "components/Custom/Charts/StockChart"
import PercentageChange from "components/Custom/PercentageChange"
import StockDateField from "components/Custom/StockDateField"
import StocksRecommendations from "components/Custom/StockInfo/StocksRecommendations"
import AuthProvider from "components/Token/AuthProvider"
import NewsTab from "components/Custom/StockInfo/NewsTab"
import SmartQA from "components/Custom/StockInfo/SmartQA"
import CompanyFinancials from "components/Custom/StockInfo/CompanyFinancials"
import StockPriceValues from "components/Custom/StockPriceValues"
import { marketStatesNames, gradesMap, chartPeriods } from "variables/constants"
import { API_URL } from "app"
import { UserContext } from "components/Contexts/UserContext"

function StockInfo() {
    const { ticker } = useParams()
    const { chartPeriod, setChartPeriod } = useContext(UserContext)

    let [stockData, setStockData] = useState(null)
    let [quoteData, setQuoteData] = useState(null)
    let [price, setPrice] = useState(null)
    let [isStockType, setIsStockType] = useState(null)
    let [currencySymbol, setCurrencySymbol] = useState("")
    let [timeZone, setTimeZone] = useState("America/New_York")
    let [prompts, setPrompts] = useState(null)
    let [promptAnswers, setPromptAnswers] = useState({})
    let [selectedPrompt, setSelectedPrompt] = useState(null)
    let [volatilityStats, setVolatilityStats] = useState(null)
    let [suggestions, setSuggestions] = useState([])
    let [chartData, setChartData] = useState(null)
    let [newsData, setNewsData] = useState(null)
    let [performanceStats, setPerformanceStats] = useState(null)
    let [analystRatings, setAnalystRatings] = useState(null)
    let [analystConsensus, setAnalystConsensus] = useState(null)
    let [priceTargets, setPriceTargets] = useState(null)
    let [activePill, setActivePill] = useState(1)
    const [authFetch] = AuthProvider()

    function getQuote() {
        let url = `${API_URL}/${ticker}/quote`
        authFetch(url, {}, `FAILED TO GET PRICE FOR ${ticker}`).then((quote) => {
            if (!quoteData || quote["market_state"] !== "CLOSED" || "pre_post_market_price" in quote) {
                if (quote["market_price"] !== price) setPrice(quote["market_price"])
                if (!quoteData || quote["pre_post_market_price"] !== quoteData["pre_post_market_price"]) setQuoteData(quote)
            }
        })
    }

    function getPrompts() {
        let url = `${API_URL}/get-gpt-prompts`
        authFetch(url, {}, `FAILED TO GET THE GPT PROMPTS`).then((data) => {
            if (data) setPrompts(data)
        })
    }

    function getPromptAnswer(prompt) {
        if (!(prompt in promptAnswers)) {
            let url = `${API_URL}/gpt-info`
            let payload = { method: "POST", body: JSON.stringify({ name: stockData["Long Name"], prompt_key: prompt }) }
            authFetch(url, payload, `FAILED TO GET THE GPT ANSWER`).then((data) => {
                if (data)
                    setPromptAnswers((prevState) => ({
                        ...prevState,
                        [prompt]: data["answer"],
                    }))
            })
        }
        setSelectedPrompt(prompt)
    }

    function getChartData() {
        let url = `${API_URL}/chart-data`
        let payload = { method: "POST", body: JSON.stringify({ ticker: ticker, period: chartPeriod }) }
        authFetch(url, payload, `FAILED TO GET CHART DATA FOR ${ticker}`).then((data) => {
            if (data) {
                setVolatilityStats(data["volatility_stats"])
                setChartData(data["chart_data"])
                setTimeZone(data["timezone"])
            } else {
                setVolatilityStats(null)
                setChartData(null)
            }
        })
    }

    function calculateDelta(targetPrice) {
        return price < targetPrice ? "+" + ((targetPrice / price - 1) * 100).toFixed(2) : ((1 - targetPrice / price) * -100).toFixed(2)
    }

    // Interval Function
    useEffect(() => {
        const interval = setInterval(() => {
            if (document.visibilityState === "visible") {
                getQuote()
                if (chartPeriod === "1D" && quoteData["market_state"] === "REGULAR") getChartData()
            }
        }, 10000)

        return () => {
            clearInterval(interval)
        }
    })

    useEffect(() => {
        let url = `${API_URL}/${ticker}/stock-info`
        authFetch(url, {}, `FAILED TO LOAD DATA FOR ${ticker}`).then((data) => {
            if (data) {
                setSuggestions(data["suggestions"])
                setStockData(data["data"])
                setCurrencySymbol(data["data"]["Currency Symbol"])
                setIsStockType(data["data"]["Equity Type"] === "Stock")
                console.log(data["data"]["Equity Type"], data["data"]["Equity Type"] === "Stock")
            }
        })
        getQuote()
        getPrompts()
        // eslint-disable-next-line
    }, [ticker])

    useEffect(() => {
        setChartData(null)
        getChartData()
        // eslint-disable-next-line
    }, [ticker, chartPeriod])

    const getNews = () => {
        let url = `${API_URL}/${ticker}/news`
        authFetch(url, {}, `FAILED TO LOAD NEWS FOR ${ticker}`).then((data) => {
            if (data) setNewsData(data["news"])
        })
    }

    const getRecommendations = () => {
        let url = `${API_URL}/${ticker}/recommendations`
        authFetch(url, {}, `FAILED TO LOAD RECOMMENDATIONS FOR ${ticker}`).then((data) => {
            if (data) {
                setAnalystRatings(data["upgrade_downgrade_history"])
                setAnalystConsensus(data["analyst_consensus"])
                setPriceTargets(data["price_targets"])
            }
        })
    }

    const parseRecommendations = () => (
        <div>
            <Row>
                <Col md={12} lg={4}>
                    <h2 className="ml-2 mb-4">Analyst Price Targets</h2>
                    <p className="mb-1 ml-4">
                        <span className="stat-label">Current Price: </span>
                        <span>{price}</span>
                    </p>
                    <p className="mb-1 ml-4 text-green">
                        <span className="stat-label">High Price Target: </span>
                        <span>{priceTargets["targetHighPrice"]}</span>
                        <span> ({calculateDelta(priceTargets["targetHighPrice"])}%)</span>
                    </p>
                    <p className="mb-1 ml-4 text-yellow">
                        <span className="stat-label">Average Price Target: </span>
                        <span>{priceTargets["targetMeanPrice"]}</span>
                        <span> ({calculateDelta(priceTargets["targetMeanPrice"])}%)</span>
                    </p>

                    <p className="mb-1 ml-4 text-red">
                        <span className="stat-label">Low Price Target: </span>
                        <span>{priceTargets["targetLowPrice"]}</span>
                        <span> ({calculateDelta(priceTargets["targetLowPrice"])}%)</span>
                    </p>
                </Col>
                <Col md={12} lg={4}>
                    <h2 className="ml-2 mb-4">Analyst Consensus</h2>
                    <BuySellDoughnut chartData={analystConsensus} />
                </Col>
                <Col md={12} lg={4}>
                    <h2 className="ml-2 mb-4">Analyst Ratings</h2>
                    <Table className="align-items-center border" responsive>
                        <thead className="thead-light">
                            <tr>
                                <th scope="col">Date</th>
                                <th scope="col">Firm</th>
                                <th scope="col">From Grade</th>
                                <th scope="col">To Grade</th>
                            </tr>
                        </thead>
                        <tbody>
                            {analystRatings.map((prop, key) => (
                                <tr key={key}>
                                    <th scope="row">{prop["date"]}</th>
                                    <td>{prop["firm"]}</td>
                                    <td>
                                        <Badge color="" className="badge-dot mr-4">
                                            <i className={gradesMap[prop["fromGrade"]]} />
                                            {prop["fromGrade"]}
                                        </Badge>
                                    </td>
                                    <td>
                                        <Badge color="" className="badge-dot mr-4">
                                            <i className={gradesMap[prop["toGrade"]]} />
                                            {prop["toGrade"]}
                                        </Badge>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </Col>
            </Row>
            <Row className="float-right mt-3">
                <p className="font-weight-bold mb-0 mr-3">Recommendations are based on data from the last three months</p>
            </Row>
        </div>
    )

    const getPerformanceStats = () => {
        let url = `${API_URL}/${ticker}/performance-stats`
        authFetch(url, {}, `FAILED TO LOAD PERFORMANCE STATS FOR ${ticker}`).then((data) => {
            if (data) setPerformanceStats(data["data"])
        })
    }

    const parsePerformanceStats = () => (
        <Col className="mx-auto" xs={12} sm={10} md={8} lg={5}>
            {
                <ListGroup className="list mt-2 mb-2" flush>
                    {performanceStats.map((field, key) => (
                        <CompanyTableRow key={key} stat_name={field[0]} value={field[1]} />
                    ))}
                </ListGroup>
            }
        </Col>
    )

    const tabsHeaders = useMemo(
        () => [
            {
                tabName: "Business Summary",
                tabOnClick: () => null,
                tabIcon: "ni ni-building",
            },
            {
                tabName: "Smart Answers",
                tabOnClick: () => null,
                tabIcon: "ni ni-atom",
            },
            {
                tabName: "Performance Stats",
                tabOnClick: () => {
                    if (!performanceStats) getPerformanceStats()
                },
                tabIcon: "ni ni-chart-bar-32",
            },
            isStockType && {
                tabName: "Company Financials",
                tabOnClick: () => {
                    if (!performanceStats) getPerformanceStats()
                },
                tabIcon: "ni ni-money-coins",
            },
            isStockType && {
                tabName: "Analyst Insights",
                tabOnClick: () => {
                    if (!analystRatings) getRecommendations()
                },
                tabIcon: "ni ni-single-02",
            },
            {
                tabName: "News",
                tabOnClick: () => {
                    if (!newsData) getNews()
                },
                tabIcon: "ni ni-single-copy-04",
            },
        ],
        // eslint-disable-next-line
        [newsData, performanceStats, analystRatings, isStockType]
    )

    const analystInsightsContent = useMemo(() => {
        return price && analystRatings && priceTargets && analystConsensus ? (
            parseRecommendations()
        ) : analystRatings !== null ? (
            <h3>Oops nothing found!</h3>
        ) : (
            <LoadingData />
        )
        // eslint-disable-next-line
    }, [price, analystRatings, priceTargets, analystConsensus])

    const tabsData = useMemo(
        () => [
            <BusinessSummaryTab businessSummary={stockData && stockData["Business Summary"]} />,
            <SmartQA prompts={prompts} selectedPrompt={selectedPrompt} promptAnswers={promptAnswers} getPromptAnswer={getPromptAnswer} />,
            performanceStats ? parsePerformanceStats() : <LoadingData />,
            isStockType && <CompanyFinancials ticker={ticker} currencySymbol={currencySymbol} />,
            isStockType && analystInsightsContent,
            <NewsTab newsData={newsData} />,
        ],
        // eslint-disable-next-line
        [stockData, newsData, performanceStats, analystInsightsContent, prompts, selectedPrompt, promptAnswers, isStockType]
    )

    return (
        <>
            <AlternativeHeader parentPage="Stock Info" childPage={ticker} />

            <Col>
                {/* Chart & Stock Info */}
                <Row className="mt-0 pt-0">
                    <Col className="mx-lg-4 px-2">
                        <Card className="card-stats">
                            {stockData ? (
                                <CardBody>
                                    {/* Header Row */}
                                    <Row>
                                        <Col className="mt-0">
                                            <div className="mb-0">
                                                <span className="h1">{ticker}</span>
                                                <FavoritesStar ticker={ticker} className="ml-1 pb-3" />
                                            </div>
                                            <div className="mt--4 mb-3">{stockData["Long Name"]}</div>
                                        </Col>
                                        <Col className="col-auto">
                                            <CompanyImage logoURL={stockData["Logo URL"]} autoSize={true} />
                                        </Col>
                                    </Row>
                                    {/* Data Row */}
                                    <Row>
                                        {/*Info Col*/}
                                        <Col sm={12} md={6} lg={5} xl={4} className="mb-3 mt-4 mt-md-5 pl-2 pl-xl-3">
                                            {/* Earnings Date */}
                                            {stockData["Days Till Earnings"] !== undefined && stockData["Days Till Earnings"] >= 0 && (
                                                <StockDateField
                                                    eventName="Earnings Date"
                                                    dateOfEvent={stockData["Earnings Date"]}
                                                    daysTillEvent={stockData["Days Till Earnings"]}
                                                />
                                            )}

                                            {/* Ex-Dividend Date */}
                                            {stockData["Days Till Ex-Dividend"] !== undefined && stockData["Days Till Ex-Dividend"] >= 0 && (
                                                <StockDateField
                                                    eventName="Ex-Dividend Date"
                                                    dateOfEvent={stockData["Ex-Dividend Date"]}
                                                    daysTillEvent={stockData["Days Till Ex-Dividend"]}
                                                />
                                            )}

                                            {/* Dividend Payout Date */}
                                            {stockData["Days Till Dividend Payout"] !== undefined &&
                                                stockData["Days Till Dividend Payout"] >= 0 && (
                                                    <StockDateField
                                                        eventName="Dividend Payout Date"
                                                        dateOfEvent={stockData["Dividend Payout Date"]}
                                                        daysTillEvent={stockData["Days Till Dividend Payout"]}
                                                    />
                                                )}

                                            <p className="mb-1">
                                                <span className="stat-label">Trail P/E: </span>
                                                <span>{stockData["Trailing PE"]}</span> —&nbsp;
                                                <span className="stat-label">Trail EPS: </span>
                                                <span>{stockData["Trailing EPS"]}</span>
                                            </p>
                                            <p className="mb-1">
                                                <span className="stat-label">Fwd P/E: </span>
                                                <span>{stockData["Forward PE"]}</span> —&nbsp;
                                                <span className="stat-label">Fwd EPS: </span>
                                                <span>{stockData["Forward EPS"]}</span>
                                            </p>

                                            {stockData["Market Cap"] && (
                                                <p className="mb-1">
                                                    <span className="stat-label">Mkt Cap: </span>
                                                    <span>{stockData["Market Cap"]}</span>
                                                </p>
                                            )}
                                            <p className="mb-1">
                                                <span className="stat-label">Yield: </span>
                                                <span>{stockData["Yield"] !== "NA" ? stockData["Yield"] + "%" : "None"}</span> —&nbsp;
                                                <span className="stat-label">Beta: </span>
                                                <span>{stockData["Beta"]}</span>
                                            </p>

                                            {["Sector", "Industry"].map(
                                                (field, key) =>
                                                    stockData[field] && (
                                                        <p className="mb-1" key={key}>
                                                            <span className="stat-label">{field}: </span>
                                                            <span>{stockData[field]}</span>
                                                        </p>
                                                    )
                                            )}

                                            {volatilityStats && (
                                                <>
                                                    <p className="mb-1">
                                                        <span className="stat-label">Volatility (STD): </span>
                                                        <span>
                                                            {`${currencySymbol}${volatilityStats["STD"]} (${volatilityStats["STD Percentage"]}%)`}
                                                        </span>
                                                    </p>
                                                    <p className="mb-1">
                                                        <span className="stat-label">Volatility (MAE): </span>
                                                        <span>
                                                            {`${currencySymbol}${volatilityStats["MAE"]} (${volatilityStats["MAE Percentage"]}%)`}
                                                        </span>
                                                    </p>
                                                </>
                                            )}

                                            {stockData["Country"] && (
                                                <p className="mb-1">
                                                    <span className="stat-label">Country: </span>
                                                    <span>{stockData["Country"]} </span>
                                                    <span className={`flag-icon flag-icon-${stockData["Country Code"]}`} />
                                                </p>
                                            )}
                                            {stockData["Website"] && (
                                                <p className="mb-1">
                                                    <span className="stat-label">Website:</span>{" "}
                                                    <a href={stockData["Website"]} target="_blank" rel="noreferrer">
                                                        {stockData["Website"]}
                                                    </a>
                                                </p>
                                            )}
                                        </Col>
                                        {/*Chart Col*/}
                                        <Col sm={12} md={6} lg={7} xl={8} className="mx-auto px-0">
                                            {stockData ? (
                                                <>
                                                    {/* Price Values & Max Gain/Drop */}
                                                    <Row className="mb-1 pl-1">
                                                        {/* Max Gain/Drop */}
                                                        {volatilityStats && (
                                                            <Col xs={12} xl={6}>
                                                                <p className="mb-1">
                                                                    <span className="h3">Max Gain: </span>
                                                                    <PercentageChange value={volatilityStats["Max Gain"]} /> —&nbsp;
                                                                    <span className="h3">Max Drop: </span>
                                                                    <PercentageChange value={volatilityStats["Max Drop"]} />
                                                                </p>
                                                            </Col>
                                                        )}
                                                        {/* Market Price */}
                                                        {quoteData && (
                                                            <Col style={{ maxWidth: 200 }}>
                                                                <StockPriceValues
                                                                    price={price}
                                                                    change={quoteData["market_price_change_percentage"]}
                                                                    marketState={
                                                                        quoteData["market_state"] === "REGULAR" ? "Price" : "At Close"
                                                                    }
                                                                />
                                                            </Col>
                                                        )}
                                                        {/* Pre/Post Market Price */}
                                                        {quoteData && "pre_post_market_price" in quoteData && (
                                                            <Col
                                                                className="pl-4"
                                                                style={{
                                                                    borderLeft: "1px solid grey",
                                                                    maxWidth: 200,
                                                                    maxHeight: 40,
                                                                }}
                                                            >
                                                                <StockPriceValues
                                                                    price={quoteData["pre_post_market_price"]}
                                                                    change={quoteData["pre_post_market_price_change_percentage"]}
                                                                    marketState={marketStatesNames[quoteData["market_state"]]}
                                                                />
                                                            </Col>
                                                        )}
                                                    </Row>
                                                    {/* Exchange & Currency */}
                                                    <Row className="mb-3 pl-3">
                                                        <div>
                                                            {stockData["Exchange"]} &bull; {stockData["Currency"]}
                                                        </div>
                                                    </Row>
                                                    {/* Time Range Buttons */}
                                                    <Row
                                                        className="mb-3 justify-content-center flex-nowrap px-3"
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        {chartPeriods.map((period, key) => (
                                                            <Button
                                                                style={{ width: 42 }}
                                                                disabled={period === chartPeriod}
                                                                onClick={() => {
                                                                    setChartPeriod(period)
                                                                }}
                                                                key={key}
                                                                color="primary"
                                                                className="py-1 px-0 mx-1 mt-1"
                                                            >
                                                                {period}
                                                            </Button>
                                                        ))}
                                                    </Row>
                                                    {/* Stock Chart */}
                                                    <div style={{ height: 500 }}>
                                                        {chartData ? (
                                                            <StockChart chartData={chartData} period={chartPeriod} timeZone={timeZone} />
                                                        ) : (
                                                            <LoadingData width={300} height={300} />
                                                        )}
                                                    </div>
                                                </>
                                            ) : (
                                                <LoadingData width={300} height={300} />
                                            )}
                                        </Col>
                                    </Row>
                                </CardBody>
                            ) : (
                                <LoadingData width={300} height={300} />
                            )}
                        </Card>
                    </Col>
                </Row>

                {/* Data Tabs */}
                {stockData && (
                    <Row>
                        <Col className="mx-lg-4 px-2">
                            {/* Tab Headers */}
                            <div className="nav-wrapper pb-0 pb-lg-3">
                                <Nav className="nav-fill flex-column flex-md-row" id="tabs-icons-text" pills role="tablist">
                                    {tabsHeaders.map((prop, key) => {
                                        key += 1
                                        if (!prop) return <></>

                                        return (
                                            <NavItem key={key}>
                                                <NavLink
                                                    aria-selected={activePill === key}
                                                    className={classnames("mb-sm-3 mb-lg-0", {
                                                        active: activePill === key,
                                                    })}
                                                    onClick={(e) => {
                                                        setActivePill(key)
                                                        prop.tabOnClick()
                                                        e.preventDefault()
                                                    }}
                                                    href="#pablo"
                                                    role="tab"
                                                >
                                                    <i className={`${prop.tabIcon} mr-2`} />
                                                    {prop.tabName}
                                                </NavLink>
                                            </NavItem>
                                        )
                                    })}
                                </Nav>
                            </div>
                            {/* Tab Content */}
                            <Card className="shadow">
                                <CardBody className="px-0 px-sm-1 px-md-2 px-lg-3">
                                    <TabContent activeTab={"tab" + activePill}>
                                        {tabsData.map((tabContent, key) => {
                                            key += 1
                                            if (!tabContent) return <></>

                                            return (
                                                <TabPane key={key} tabId={"tab" + key} className="px-3 py-2">
                                                    {tabContent}
                                                </TabPane>
                                            )
                                        })}
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {/* Suggestions */}
                {suggestions.length > 0 && <StocksRecommendations suggestions={suggestions} />}
            </Col>
        </>
    )
}

export default StockInfo
