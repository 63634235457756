/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react"
// reactstrap components
import {
    Input,
    FormGroup,
    InputGroup,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Container,
    Row,
    Col,
    Button,
    Form,
} from "reactstrap"
// core components
import { UserContext } from "components/Contexts/UserContext"
import AlternativeHeader from "components/Headers/AlternativeHeader.js"
import AuthProvider from "components/Token/AuthProvider"
import CompanyTable from "components/Custom/CompanyTable"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { API_URL } from "app"
import { Modal } from "react-bootstrap"

function CompanyPerformance() {
    const [dashboardData, setDashboardData] = useState(null)
    const [sector, setSector] = useState(null)
    const [industry, setIndustry] = useState(null)
    const [searchText, setSearchText] = useState("")
    const [modalOpen, setModalOpen] = useState(false)
    const [userStats, setUserStats] = useState([])
    const [modalData, setModalData] = useState([])
    const [periods, setPeriods] = useState([])
    const [stats, setStats] = useState([])
    const { isPersonalPageView } = useContext(UserContext)
    const [authFetch] = AuthProvider()

    const refreshDashboardData = () => {
        let url = `${API_URL}/company-dashboard`
        let payload = { method: "POST", body: JSON.stringify({ is_favorites: isPersonalPageView }) }
        setDashboardData(null)
        authFetch(url, payload, null).then((data) => {
            if (data) {
                setDashboardData(data.data)
                setUserStats(data.stats)
                setModalData(data.stats)
            } else setDashboardData(null)
        })
    }

    const getStatsAndPeriods = () => {
        let url = `${API_URL}/get-periods-and-stats`
        authFetch(url, { method: "GET" }, null).then((data) => {
            if (data) {
                setStats(data.stats)
                setPeriods(data.periods)
            } else {
                setStats([])
                setPeriods([])
            }
        })
    }

    useEffect(() => {
        refreshDashboardData()
        getStatsAndPeriods()
        // eslint-disable-next-line
    }, [isPersonalPageView])

    const applySearch = (event) => {
        let searchFieldText = event.target.value.toLocaleLowerCase()
        setSearchText(searchFieldText)
    }

    const parsePageData = (data) =>
        data.map((prop, key) => {
            return (
                <Col sm="12" md="6" xl="3" key={key}>
                    <CompanyTable
                        ticker={prop.ticker}
                        company_name={prop.name}
                        logo_url={prop.logo_url}
                        tableData={prop.stats}
                        key={prop.ticker}
                    />
                </Col>
            )
        })

    let filteredList = dashboardData?.filter(
        (element) =>
            (element.ticker.toLocaleLowerCase().includes(searchText) || element.name.toLocaleLowerCase().includes(searchText)) &&
            (element.sector === sector || sector === null) &&
            (element.industry === industry || industry === null)
    )

    let sectors = filteredList && Array.from(new Set(filteredList.map((prop) => prop.sector))).sort()
    let industries = filteredList && Array.from(new Set(filteredList.map((prop) => prop.industry))).sort()

    const checkIncompleteStat = () =>
        modalData.length > 0 && (modalData[modalData.length - 1].period === "none" || modalData[modalData.length - 1].stat === "none")

    const saveChanges = () => {
        setModalOpen(false)

        let requestBody = modalData
        if (checkIncompleteStat()) requestBody = [...modalData.slice(0, modalData.length - 1)]

        let url = `${API_URL}/update-user-company-stats`
        let payload = { method: "POST", body: JSON.stringify({ userStats: requestBody }) }
        authFetch(url, payload, "FAILED TO SAVE NEW STATS").then((data) => {
            if (data) refreshDashboardData()
            else setModalData(userStats)
        })
    }

    const discardChanges = () => {
        setModalOpen(false)
        setModalData(userStats)
    }

    return (
        <>
            <AlternativeHeader parentPage="Dashboards" childPage="Company Dashboard" viewSwitchSettings={{}} />
            {!filteredList ? (
                <LoadingData />
            ) : (
                <Container fluid>
                    {/***************  Search & Edit   ***************/}
                    <Row>
                        <Col md="12" lg="6" xl="4" className="mx-auto">
                            <FormGroup>
                                <InputGroup className="mb-3">
                                    <Input
                                        className="rounded"
                                        placeholder="search..."
                                        type="search"
                                        style={{ fontSize: 16 }}
                                        onChange={applySearch}
                                        value={searchText}
                                    />
                                    {isPersonalPageView && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="ml-3 btn-primary"
                                            style={{ fontSize: 16 }}
                                            onClick={() => setModalOpen(true)}
                                        >
                                            Edit
                                        </Button>
                                    )}
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    {/***************  Filter Controls   ***************/}
                    <Row>
                        <Col md={12} lg={6} xl={4} className="mx-auto">
                            <Row>
                                {/***************  Sector Filter   *****************/}
                                <Col sm={12} md={6} className="mb-3">
                                    <span className="font-weight-bold mr-2">Sector: </span>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret color="secondary">
                                            {sector || "All"}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#pablo" onClick={() => setSector(null)} key={0}>
                                                All
                                            </DropdownItem>
                                            {sectors.map((prop, key) => {
                                                return (
                                                    <DropdownItem href="#pablo" onClick={() => setSector(prop)} key={key + 1}>
                                                        {prop}
                                                    </DropdownItem>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                                {/***************  Industry Filter   *****************/}
                                <Col sm={12} md={6} className="mb-3">
                                    <span className="font-weight-bold mr-2">Industry: </span>
                                    <UncontrolledDropdown>
                                        <DropdownToggle caret color="secondary">
                                            {industry || "All"}
                                        </DropdownToggle>
                                        <DropdownMenu>
                                            <DropdownItem href="#pablo" onClick={() => setIndustry(null)} key={0}>
                                                All
                                            </DropdownItem>
                                            {industries.map((prop, key) => {
                                                return (
                                                    <DropdownItem href="#pablo" onClick={() => setIndustry(prop)} key={key + 1}>
                                                        {prop}
                                                    </DropdownItem>
                                                )
                                            })}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/******************  Content   ******************/}
                    <Row>
                        {filteredList.length > 0 ? (
                            parsePageData(filteredList)
                        ) : userStats && userStats.length > 0 ? (
                            <h1 className="mx-auto my-5 text-center">Nothing matches search</h1>
                        ) : (
                            <h1 className="mx-auto my-5 text-center">
                                You currently do not have any stats saved.
                                <br />
                                Add new ones using the "edit" button above.
                            </h1>
                        )}
                    </Row>
                </Container>
            )}

            {/******************  Editing Modal   ******************/}
            {isPersonalPageView && (
                <Modal className="modal-dialog-centered" show={modalOpen} centered>
                    <div className="modal-header">
                        <h5 className="modal-title" id="modal-title-default">
                            Specify the stats you want to track
                        </h5>
                        <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={discardChanges}>
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Form role="form">
                            {modalData.length > 0 ? (
                                <FormGroup>
                                    <Row>
                                        <Col xs={5} className="text-center">
                                            Stat Type
                                        </Col>
                                        <Col xs={5} className="text-center">
                                            Period
                                        </Col>
                                    </Row>
                                    {modalData.map((mainProp, mainKey) => (
                                        <Row className="my-3" key={mainKey}>
                                            {/* Stat Name */}
                                            <Col xs={5}>
                                                <Input
                                                    id="statSelect"
                                                    type="select"
                                                    value={mainProp.stat}
                                                    onChange={(e) => {
                                                        setModalData([
                                                            ...modalData.slice(0, mainKey),
                                                            { period: modalData[mainKey].period, stat: e.target.value },
                                                            ...modalData.slice(mainKey + 1),
                                                        ])
                                                    }}
                                                >
                                                    >
                                                    <option value="none" disabled hidden />
                                                    {stats.map((prop, key) => (
                                                        <option key={key} value={prop[0]}>
                                                            {prop[1]}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            {/* Period */}
                                            <Col xs={5}>
                                                <Input
                                                    id="periodSelect"
                                                    type="select"
                                                    value={mainProp.period}
                                                    onChange={(e) => {
                                                        setModalData([
                                                            ...modalData.slice(0, mainKey),
                                                            { stat: modalData[mainKey].stat, period: e.target.value },
                                                            ...modalData.slice(mainKey + 1),
                                                        ])
                                                    }}
                                                >
                                                    <option value="none" disabled hidden />
                                                    {periods.map((prop, key) => (
                                                        <option key={key} value={prop[0]}>
                                                            {prop[1]}
                                                        </option>
                                                    ))}
                                                </Input>
                                            </Col>
                                            {/* Remove Button */}
                                            <Col xs={2}>
                                                <img
                                                    alt="remove"
                                                    className="ml-1 mt-2"
                                                    width={24}
                                                    src={require("assets/img/icons/utility/remove_circle.svg").default}
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setModalData([...modalData.slice(0, mainKey), ...modalData.slice(mainKey + 1)])
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                    ))}
                                </FormGroup>
                            ) : (
                                <h3 className="mt-3 text-center">
                                    Nothing added yet
                                    <br />
                                    Click "Add Stat" below to add to your list
                                </h3>
                            )}
                            <Button
                                color="info"
                                className="float-right"
                                onClick={() => {
                                    setModalData([...modalData, { period: "none", stat: "none" }])
                                }}
                                disabled={checkIncompleteStat()}
                            >
                                Add Stat
                            </Button>
                        </Form>
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="success"
                            type="button"
                            className="mr-auto"
                            disabled={modalData.length === 0 || checkIncompleteStat()}
                            onClick={saveChanges}
                        >
                            Save changes
                        </Button>
                    </div>
                </Modal>
            )}
        </>
    )
}

export default CompanyPerformance
