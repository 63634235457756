import React, { useContext, useEffect, useState } from "react"
import NotificationAlert from "react-notification-alert"
import { API_URL } from "app"
import AuthProvider from "components/Token/AuthProvider"
import { UserContext } from "components/Contexts/UserContext"

function FavoritesStar({ ticker, length, className }) {
    const { notifyRef } = useContext(UserContext)
    const [isFavorite, setIsFavorite] = useState(null)
    const [favoritesURI, setFavoritesURI] = useState(null)
    const [authFetch] = AuthProvider()
    className = className || "ml-1 mb-0"

    useEffect(() => {
        setFavoritesURI(require(isFavorite ? "assets/img/icons/utility/star.svg" : "assets/img/icons/utility/star_outline.svg").default)
    }, [isFavorite])

    useEffect(() => {
        let url = `${API_URL}/is_favorite`
        let payload = { method: "POST", body: JSON.stringify({ ticker }) }
        authFetch(url, payload, `FAILED TO GET FAVORITE FOR ${ticker}`).then((data) => {
            if (data) setIsFavorite(data["is_favorite"])
        })
        // eslint-disable-next-line
    }, [ticker])

    const updateFavorites = () => {
        let updateURL = `${API_URL}/${isFavorite ? "remove_from" : "add_to"}_favorites`
        let payload = { method: "POST", body: JSON.stringify({ ticker }) }
        let options = {}

        authFetch(updateURL, payload, null).then((data) => {
            if (data) {
                let title = `${ticker} has been ${isFavorite ? "removed from" : "added to"} your favorites`
                options = {
                    place: "tr",
                    message: (
                        <div className="alert-text">
                            <span className="alert-title" data-notify="title">
                                {title}
                            </span>
                        </div>
                    ),
                    type: isFavorite ? "danger" : "success",
                    icon: "ni ni-bell-55",
                    autoDismiss: 2.3,
                }
                notifyRef.current.notificationAlert(options)
                setIsFavorite(!isFavorite)
            } else {
                let title = `Failed to ${isFavorite ? "remove from" : "add to"} favorites`
                options = {
                    place: "tr",
                    message: (
                        <div className="alert-text">
                            <span className="alert-title" data-notify="title">
                                {title}
                            </span>
                        </div>
                    ),
                    type: "danger",
                    icon: "ni ni-bell-55",
                    autoDismiss: 2.3,
                }
                notifyRef.current.notificationAlert(options)
            }
        })
    }

    if (isFavorite === null) return <div className={className}></div>

    return (
        <>
            <img
                onClick={updateFavorites}
                alt="star"
                className={className}
                width={length || 42}
                src={favoritesURI}
                style={{ cursor: "pointer" }}
            />
            <div className="rna-wrapper">
                <NotificationAlert ref={notifyRef} />
            </div>
        </>
    )
}

export default FavoritesStar
