/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
import { createRoot } from "react-dom/client"

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css"
import "react-perfect-scrollbar/dist/css/styles.css"
import "@fullcalendar/common/main.min.css"
import "@fullcalendar/daygrid/main.min.css"
import "sweetalert2/dist/sweetalert2.min.css"
import "select2/dist/css/select2.min.css"
import "quill/dist/quill.core.css"
import "@fortawesome/fontawesome-free/css/all.min.css"
// plugins styles downloaded
import "assets/vendor/nucleo/css/nucleo.css"
import "assets/vendor/nucleo/css/nucleo-icons.css"
// core styles
import "assets/css/argon-dashboard-pro-react.css"

import { UserProvider } from "components/Contexts/UserContext"
import { MarketMovementModalProvider } from "components/Contexts/MarketMovementModalContext"
import App from "app"

const root = createRoot(document.getElementById("root"))

root.render(
    <UserProvider>
        <MarketMovementModalProvider>
            <App />
        </MarketMovementModalProvider>
    </UserProvider>
)
