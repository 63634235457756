import Lottie from "react-lottie-player"

import lottieJson from "assets/lottie/39620-404-network.json"

import AuthHeader from "components/Headers/AuthHeader"
import React from "react"
import { Row } from "reactstrap"

function NotFound() {
    let size = Math.min(window.innerWidth, 600)

    return (
        <>
            <AuthHeader title="404 Error - Page Not Found" lead="The page you are trying to reach does not exist!" />
            <Row className="justify-content-center mb-6">
                <a href="/" className="text-xl">
                    Go to login page!
                </a>
            </Row>
            <Row>
                <div className="mx-auto mt--5">
                    <Lottie className="mx-auto" animationData={lottieJson} speed={0.5} style={{ width: size, height: size }} loop play />
                </div>
            </Row>
        </>
    )
}

export default NotFound
