import { Card, CardBody, CardImg, CardText, CardTitle, Col, Row } from "reactstrap"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"

function NewsTab({ newsData }) {
    return newsData ? (
        <Row>
            {newsData.map((prop, key) => (
                <Col xs={6} sm={6} lg={4} xl={3}>
                    <Card key={key}>
                        <a target="_blank" rel="noreferrer" href={prop["link"]}>
                            <CardImg
                                src={prop["article_img_url"] || require("assets/img/icons/utility/image_not_supported.png").default}
                                onError={(e) => {
                                    e.target.onerror = null
                                    e.target.src = require("assets/img/icons/utility/image_not_supported.png").default
                                }}
                                height={200}
                                style={{ objectFit: "cover" }}
                            />
                            <CardBody>
                                <CardTitle>{prop["title"]}</CardTitle>
                                <CardText>
                                    {prop["publisher_img_url"] ? (
                                        <img
                                            alt={prop["publisher"]}
                                            src={prop["publisher_img_url"]}
                                            onError={(e) => {
                                                e.target.onerror = null
                                            }}
                                            style={{
                                                maxWidth: "100%",
                                                height: "auto",
                                                maxHeight: 35,
                                            }}
                                        />
                                    ) : (
                                        <span className="h2">{prop["publisher"]}</span>
                                    )}
                                    <span className="h4 float-right pt-2">{prop["published_time"]}</span>
                                </CardText>
                            </CardBody>
                        </a>
                    </Card>
                </Col>
            ))}
        </Row>
    ) : newsData !== null ? (
        <h3>Oops nothing found!</h3>
    ) : (
        <LoadingData />
    )
}

export default NewsTab
