import { Figure } from "react-bootstrap"
import CompanyImage from "components/Custom/CompanyImage"

function CompanyFigure({ logoURL, ticker, width, height, autoSize, textStyle, hideTicker, neumorphic, zoom, extraClasses }) {
    return (
        <Figure className={"mb-0 " + (extraClasses ? extraClasses : "")}>
            <a href={`/stock/${ticker}`} className="mt-1" target="_blank" rel="noreferrer">
                <CompanyImage
                    neumorphic={neumorphic}
                    zoom={zoom}
                    logoURL={logoURL}
                    width={width}
                    height={height}
                    autoSize={autoSize}
                    alt={ticker}
                />
            </a>
            {!hideTicker && (
                <Figure.Caption className="text-center" style={textStyle}>
                    {ticker}
                </Figure.Caption>
            )}
        </Figure>
    )
}

export default CompanyFigure
