import React from "react"
import ExpandableText from "components/Custom/ExpandableText"

function BusinessSummaryTab({ businessSummary }) {
    return (
        <div className="description">
            {businessSummary ? <ExpandableText text={businessSummary} maxLength={420} /> : <h3>Not Available</h3>}
        </div>
    )
}

export default BusinessSummaryTab
