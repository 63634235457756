import { Col, Row } from "reactstrap"
import React from "react"
import { marketStatesNames } from "variables/constants"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import StockPriceCard from "components/Custom/StockPriceCard"

function getPriceChange(stockQuote) {
    if (stockQuote.market_state === "REGULAR" || stockQuote.pre_post_market_price_change_percentage === null)
        return stockQuote.market_price_change_percentage
    return stockQuote.pre_post_market_price_change_percentage
}

function sortingFunction(a, b, field, direction) {
    let value_a
    let value_b
    let directionValue = direction === "desc" ? 1 : -1

    if (field === "price_change") {
        value_a = getPriceChange(a)
        value_b = getPriceChange(b)
    } else {
        value_a = a[field]
        value_b = b[field]
    }
    if (value_a > value_b) return -directionValue
    else if (value_a < value_b) return directionValue
    else return 0
}

export default function StockPrices({ data, sortingField, sortingDirection }) {
    sortingField = sortingField || "market_cap"
    sortingDirection = sortingDirection || "desc"

    return (
        <Row>
            {data ? (
                data.length === 0 ? (
                    <h1>You do not have any favorite tickers to show here</h1>
                ) : (
                    data
                        .sort((a, b) => sortingFunction(a, b, sortingField, sortingDirection))
                        .map((data, index) => (
                            <Col xs={12} sm={6} md={4} lg={3} key={index} className="mb--4 mb-sm--3">
                                <StockPriceCard
                                    logoURL={data.logo_url}
                                    ticker={data.ticker}
                                    name={data.name}
                                    price={data.pre_post_market_price ? data.pre_post_market_price : data.market_price}
                                    priceChange={
                                        data.pre_post_market_price
                                            ? data.pre_post_market_price_change_percentage
                                            : data.market_price_change_percentage
                                    }
                                    marketState={marketStatesNames[data.market_state]}
                                />
                            </Col>
                        ))
                )
            ) : (
                <LoadingData />
            )}
        </Row>
    )
}
