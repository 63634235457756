import React from "react"
import PercentageChange from "components/Custom/PercentageChange"

function StockPriceValues({ price, change, marketState }) {
    return (
        <>
            <div>
                {/* Market Price */}
                <span className="h3 mb-0">{price.toFixed(2)}</span>

                {/* Market Price Change */}
                <PercentageChange value={change} className="ml-2" textStyle={{ fontSize: 12 }} animate={false} />
            </div>
            <div className="mt--2" style={{ fontSize: 14 }}>
                <span>{marketState}</span>
            </div>
        </>
    )
}

export default StockPriceValues
