import CompanyFigure from "components/Custom/CompanyFigure"

function CalendarCol({ dayEvents }) {
    return dayEvents.map((prop, key) => {
        // should be a function to calculate it, the bigger the width the less the shrinkage
        let side = Math.max(~~(window.innerWidth / 27), 42)
        return (
            <div className="m-1" key={key}>
                <CompanyFigure zoom={true} width={side} height={side} ticker={prop["Ticker"]} logoURL={prop["Icon URL"]} />
            </div>
        )
    })
}

export default CalendarCol
