import ReactTextTransition, { presets } from "react-text-transition"

const GREEN = "#2dce89"
const RED = "#f5365c"
const BLUE = "#11cdef"

function PercentageChange({ value, animate, textStyle, className }) {
    /**
     Component to display a percentage change value
     @param {number} value - The percentage value
     @param {boolean} animate - Whether to animate value transitions
     @param {object} textStyle - Styles applied to text
     @param {string} className - Additional classNames
     **/
    // eslint-disable-next-line
    let color = value === 0 ? BLUE : value > 0 ? GREEN : RED
    textStyle = textStyle || {}
    textStyle.color = color
    value = parseFloat(value).toFixed(2)

    return animate ? (
        <ReactTextTransition className={className} springConfig={presets.stiff} style={textStyle} inline>
            {value > 0 && "+"}
            {value}%
        </ReactTextTransition>
    ) : (
        <span className={className} style={textStyle}>
            <span style={{ color }}>
                {value > 0 && "+"}
                {value}%
            </span>
        </span>
    )
}

export default PercentageChange
