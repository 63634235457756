import { Badge, Col, Row } from "reactstrap"

import SmartLoadingData from "components/Custom/LottieAnimations/SmartLoadingData"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"

function SmartQA({ prompts, selectedPrompt, promptAnswers, getPromptAnswer }) {
    return prompts ? (
        <Col>
            <Row className="mb-4 justify-content-center">
                {selectedPrompt && selectedPrompt in promptAnswers ? (
                    <pre style={{ whiteSpace: "pre-wrap", minHeight: 300 }}>{promptAnswers[selectedPrompt]}</pre>
                ) : selectedPrompt ? (
                    <SmartLoadingData />
                ) : (
                    <h2 className="mb-5">Select a prompt from the below suggestions</h2>
                )}
            </Row>
            <Row>
                {Object.entries(prompts).map(([prompt_key, prompt_statement]) => (
                    <Col key={prompt_key} className="text-center" xs={12} sm={6} md={3}>
                        <Badge
                            className="badge-pill mb-2"
                            color={selectedPrompt === prompt_key ? "primary" : "secondary"}
                            style={{ fontSize: 14 }}
                            href="#pablo"
                            onClick={(e) => {
                                getPromptAnswer(prompt_key)
                                e.preventDefault()
                            }}
                        >
                            <span style={{ whiteSpace: "pre-wrap" }}>{prompt_statement}</span>
                        </Badge>
                    </Col>
                ))}
            </Row>
        </Col>
    ) : (
        <LoadingData />
    )
}

export default SmartQA
