import { Card, CardBody, CardTitle, Col, ListGroup, Row } from "reactstrap"
import CompanyTableRow from "components/Custom/CompanyTableRow"
import CompanyFigure from "components/Custom/CompanyFigure"

function CompanyTable({ company_name, ticker, logo_url, tableData }) {
    const parseTableData = (data) => data.map((prop, key) => <CompanyTableRow stat_name={prop[0]} value={prop[1]} key={key} />)

    return (
        <Card className="card-stats blur--hover">
            <CardBody>
                <Row>
                    <Col>
                        <CardTitle tag="h3" className="h3 mb-0 card-title">
                            {company_name}
                        </CardTitle>
                    </Col>
                    <Col className="col-auto mb-1">
                        <CompanyFigure
                            neumorphic={true}
                            zoom={true}
                            width={50}
                            height={50}
                            logoURL={logo_url}
                            ticker={ticker}
                            alt={ticker}
                            hideTicker={true}
                        />
                    </Col>
                </Row>
                <ListGroup className="list mt-2 mb-2" flush>
                    {parseTableData(tableData)}
                </ListGroup>
            </CardBody>
        </Card>
    )
}

export default CompanyTable
