function triggerNotification(notifyRef, message, type) {
    let options = {
        place: "tr",
        message: (
            <div className="alert-text">
                <span className="alert-title" data-notify="title">
                    {message}
                </span>
            </div>
        ),
        type: type,
        icon: "ni ni-bell-55",
        autoDismiss: 2.3,
    }

    notifyRef.current.notificationAlert(options)
}

export default triggerNotification
