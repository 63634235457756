import { Card, CardBody, CardHeader, CardTitle, ListGroup, Row, Col } from "reactstrap"
import StatTableRow from "components/Custom/StatTableRow"
import CardControls from "components/Custom/CardControls"
import QueryInfoDropDown from "components/Custom/QueryInfoDropDown"

function StatTable({ title, tableIndex, tableData, sortingCol, queryData, buttonsConfig }) {
    let maxValue = Math.max(...tableData.map((prop) => prop.value))
    let minValue = Math.min(...tableData.map((prop) => prop.value))

    if (minValue < 0) maxValue = Math.abs(minValue)

    const parseTableData = (data) =>
        data.length === 0 ? (
            <h2 className="mx-auto">Nothing To Display</h2>
        ) : (
            data.map((prop, key) => (
                <StatTableRow
                    name={prop.name}
                    ticker={prop.ticker}
                    maxValue={maxValue}
                    value={prop.value}
                    logoURL={prop.logo_url}
                    isMarketCapValue={sortingCol === "Market Cap"}
                    key={key}
                />
            ))
        )

    return (
        <>
            <Card>
                <CardHeader className="pb-1 pt-3">
                    <Row className="align-items-center">
                        <Col xs={10} md={11}>
                            <CardTitle className="h2 mb-3">{title}</CardTitle>
                        </Col>
                        {queryData && <QueryInfoDropDown queryData={queryData} />}
                    </Row>
                    <Row className="align-items-center">
                        <CardTitle className="h4 mb-0 pr-1 ml-auto">{sortingCol}</CardTitle>
                    </Row>
                </CardHeader>
                <CardBody>
                    <ListGroup className="list mt--3" flush>
                        {parseTableData(tableData)}
                    </ListGroup>
                    {buttonsConfig && <CardControls tableIndex={tableIndex} buttonsConfig={buttonsConfig} />}
                </CardBody>
            </Card>
        </>
    )
}

export default StatTable
