/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react"
// react library for routing
import { useLocation, Route, Switch, Redirect } from "react-router-dom"

// core components
import AuthFooter from "components/Footers/AuthFooter.js"
import Login from "views/Login.js"
import Register from "views/Register.js"
import NotFound from "views/error/UnAuth404"

function Auth() {
    const location = useLocation()
    const mainContentRef = React.useRef(null)
    React.useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContentRef.current.scrollTop = 0
        document.body.classList.add("bg-lighter")
        // Specify how to clean up after this effect:
        return function cleanup() {
            document.body.classList.remove("bg-lighter")
        }
    })
    React.useEffect(() => {
        document.documentElement.scrollTop = 0
        document.scrollingElement.scrollTop = 0
        mainContentRef.current.scrollTop = 0
    }, [location])

    return (
        <>
            <div className="main-content" ref={mainContentRef}>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/register" component={Register} />
                    <Redirect from="/" to="/login" />
                    <Route path="*" component={NotFound} />
                </Switch>
            </div>
            <AuthFooter />
        </>
    )
}

export default Auth
