import { Col, ListGroupItem, Progress, Row } from "reactstrap"
import { prettyFinancialFigure } from "utils/strings"
import CompanyFigure from "components/Custom/CompanyFigure"
import PercentageChange from "components/Custom/PercentageChange"
// import FavoritesStar from "components/Custom/FavoritesStar";

function StatTableRow({ logoURL, name, ticker, value, maxValue, isMarketCapValue }) {
    value = parseFloat(value)
    return (
        <>
            <ListGroupItem className="px-0 py-0 mt-1">
                <Row className="align-items-center">
                    <Col className="col-auto">
                        <CompanyFigure
                            neumorphic={true}
                            zoom={true}
                            logoURL={logoURL}
                            ticker={ticker}
                            height={50}
                            width={50}
                            extraClasses="mb--5"
                        />
                    </Col>
                    {/*<Col xs={1} className="pb-2 px-1">*/}
                    {/*    <FavoritesStar ticker={ticker} length={32}/>*/}
                    {/*</Col>*/}
                    <Col className="pb-4 pl-0">
                        <div className="progress-info">
                            <Col className="px-0">
                                <Row className="ml-1 mb-0">
                                    <h4 className="my-0">{name}</h4>
                                </Row>
                                <Row className="float-right mb--2 mt--1 mr-1">
                                    <div className="progress-percentage">
                                        {isMarketCapValue ? (
                                            <span>{prettyFinancialFigure(value, "$", 2)}</span>
                                        ) : (
                                            <PercentageChange value={value} />
                                        )}
                                    </div>
                                </Row>
                            </Col>
                        </div>
                        <Progress
                            className="progress mb-0"
                            max={maxValue}
                            value={Math.abs(value)}
                            color={value > 0 ? "success" : value < 0 ? "danger" : "info"}
                        />
                    </Col>
                </Row>
            </ListGroupItem>
        </>
    )
}

export default StatTableRow
