import Lottie from "react-lottie-player"

import lottieJson from "assets/lottie/39620-404-network.json"

function NotFound() {
    let size = Math.min(window.innerWidth, 600)

    return (
        <div className="mx-auto mt-5">
            <h1 className="text-center" color="black">
                404 Error - Page Not Found
            </h1>
            <h1 className="text-center" color="black">
                The page you are trying to reach does not exist!
            </h1>
            <Lottie className="mx-auto" animationData={lottieJson} speed={0.5} style={{ width: size, height: size }} loop play />
        </div>
    )
}

export default NotFound
