/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const Chart = require("chart.js")

var mode = "light" //(themeMode) ? themeMode : 'light';
var fonts = {
    base: "Open Sans",
}

// Colors
var colors = {
    gray: {
        100: "#f6f9fc",
        200: "#e9ecef",
        300: "#dee2e6",
        400: "#ced4da",
        500: "#adb5bd",
        600: "#8898aa",
        700: "#525f7f",
        800: "#32325d",
        900: "#212529",
    },
    theme: {
        default: "#172b4d",
        primary: "#5e72e4",
        secondary: "#f4f5f7",
        info: "#11cdef",
        success: "#2dce89",
        danger: "#f5365c",
        warning: "#fb6340",
    },
    black: "#12263F",
    white: "#FFFFFF",
    transparent: "transparent",
}

// Chart.js global options
function chartOptions() {
    // Options
    var options = {
        defaults: {
            global: {
                responsive: true,
                maintainAspectRatio: false,
                defaultColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
                defaultFontColor: mode === "dark" ? colors.gray[700] : colors.gray[600],
                defaultFontFamily: fonts.base,
                defaultFontSize: 13,
                layout: {
                    padding: 0,
                },
                legend: {
                    display: false,
                    position: "bottom",
                    labels: {
                        usePointStyle: true,
                        padding: 16,
                    },
                },
                elements: {
                    point: {
                        radius: 0,
                        backgroundColor: colors.theme["primary"],
                    },
                    line: {
                        tension: 0.3,
                        borderWidth: 4,
                        borderColor: colors.theme["primary"],
                        backgroundColor: colors.transparent,
                        borderCapStyle: "rounded",
                    },
                    rectangle: {
                        backgroundColor: colors.theme["warning"],
                    },
                    arc: {
                        backgroundColor: colors.theme["primary"],
                        borderColor: mode === "dark" ? colors.gray[800] : colors.white,
                        borderWidth: 4,
                    },
                },
                tooltips: {
                    enabled: true,
                    mode: "index",
                    intersect: false,
                },
            },
            doughnut: {
                cutoutPercentage: 83,
                legendCallback: function (chart) {
                    var data = chart.data
                    var content = ""

                    data.labels.forEach(function (label, index) {
                        var bgColor = data.datasets[0].backgroundColor[index]

                        content += '<span class="chart-legend-item">'
                        content += '<i class="chart-legend-indicator" style="background-color: ' + bgColor + '"></i>'
                        content += label
                        content += "</span>"
                    })

                    return content
                },
            },
        },
    }

    // yAxes
    Chart.scaleService.updateScaleDefaults("linear", {
        gridLines: {
            borderDash: [2],
            borderDashOffset: [2],
            color: mode === "dark" ? colors.gray[900] : colors.gray[300],
            drawBorder: false,
            drawTicks: false,
            lineWidth: 1,
            zeroLineWidth: 1,
            zeroLineColor: mode === "dark" ? colors.gray[900] : colors.gray[300],
            zeroLineBorderDash: [2],
            zeroLineBorderDashOffset: [2],
        },
        ticks: {
            padding: 10,
        },
    })

    // xAxes
    Chart.scaleService.updateScaleDefaults("category", {
        gridLines: {
            drawBorder: false,
            drawOnChartArea: true,
            drawTicks: false,
        },
        ticks: {
            padding: 7,
        },
    })

    return options
}

// Parse global options
function parseOptions(parent, options) {
    for (var item in options)
        if (typeof options[item] !== "object") parent[item] = options[item]
        else parseOptions(parent[item], options[item])
}

module.exports = {
    chartOptions,
    parseOptions,
}
