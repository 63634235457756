/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// react library for routing
import { BrowserRouter, Route, Switch } from "react-router-dom"
import NotificationAlert from "react-notification-alert"
import React, { useContext } from "react"

import AdminLayout from "layouts/Admin.js"
import AuthLayout from "layouts/Auth.js"
import { UserContext } from "components/Contexts/UserContext"

const API_URL = process.env.REACT_APP_ENV === "DEV" ? "http://127.0.0.1:5000" : "https://api.make-life-eazy.com"

export { API_URL }

function App() {
    const { isLoggedIn, notifyRef } = useContext(UserContext)

    return (
        <>
            <BrowserRouter>
                <Switch>{isLoggedIn ? <Route component={AdminLayout} /> : <Route component={AuthLayout} />}</Switch>
            </BrowserRouter>
            {/* Universal notification banner for the whole app */}
            <div className="rna-wrapper">
                <NotificationAlert ref={notifyRef} />
            </div>
        </>
    )
}

export default App
