import Marquee from "react-easy-marquee"
import CompanyFigure from "components/Custom/CompanyFigure"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import AlternativeHeader from "components/Headers/AlternativeHeader"
import AuthProvider from "components/Token/AuthProvider"
import { API_URL } from "app"
import { useState, useEffect } from "react"

function Explore() {
    let [trending, setTrending] = useState([])
    const [authFetch] = AuthProvider()

    useEffect(() => {
        let url = `${API_URL}/explore`
        authFetch(url, null, "FAILED TO LOAD EXPLORE PAGE").then((data) => {
            if (data) setTrending(data["trending"])
            else setTrending(null)
        })

        // eslint-disable-next-line
    }, [])

    return (
        <>
            <AlternativeHeader parentPage="Explore" />

            <h1 className="ml-5">Trending Stocks:</h1>
            {trending ? (
                trending.length > 0 ? (
                    <Marquee duration={42000} height="150px" pauseOnHover={true}>
                        {trending.map(({ ticker, logo_url }) => (
                            <CompanyFigure
                                autoSize={true}
                                zoom={true}
                                neumorphic={true}
                                extraClasses={"mx-1 p-1"}
                                ticker={ticker}
                                logoURL={logo_url}
                                key={ticker}
                            />
                        ))}
                    </Marquee>
                ) : (
                    <LoadingData />
                )
            ) : (
                <h1 className="ml-5">FAILED TO LOAD DATA</h1>
            )}
        </>
    )
}

export default Explore
