import { Col, Row, ButtonGroup, Button } from "reactstrap"
import { useEffect, useState } from "react"
import FinancialsBarChart from "components/Custom/Charts/FinancialsBarChart"
import { API_URL } from "app"
import classnames from "classnames"
import AuthProvider from "components/Token/AuthProvider"
import { toSnakeCase } from "utils/strings"

const FINANCIAL_DOCUMENTS = ["Income Statement", "Balance Sheet", "Cash Flow"]
const FINANCIAL_PERIODS = ["Quarterly", "Yearly"]

function CompanyFinancials({ ticker, currencySymbol }) {
    const [authFetch] = AuthProvider()
    let [financialsData, setFinancialsData] = useState({})
    let [selectedFinancialsDocument, setSelectedFinancialsDocument] = useState("income_statement")
    let [selectedFinancialsPeriod, setSelectedFinancialsPeriod] = useState("quarterly")

    function getFinancialsData(financials_document, period) {
        let key = `${period}_${financials_document}`
        if (!(key in financialsData)) {
            let url = `${API_URL}/${ticker}/financials/${financials_document}/${period}`
            authFetch(url, { method: "GET" }, `FAILED TO GET FINANCIALS DATA FOR ${key}`).then((data) => {
                let result = data || []
                setFinancialsData((prevState) => ({
                    ...prevState,
                    [key]: result,
                }))
            })
        }
        setSelectedFinancialsDocument(financials_document)
        setSelectedFinancialsPeriod(period)
    }

    useEffect(() => {
        getFinancialsData(selectedFinancialsDocument, selectedFinancialsPeriod)
        // eslint-disable-next-line
    }, [selectedFinancialsDocument, selectedFinancialsPeriod])

    let key = `${selectedFinancialsPeriod}_${selectedFinancialsDocument}`
    let data = financialsData[key]

    return (
        <Col className="justify-content-center">
            <Row>
                <Col xs={12} md={6} className="mx-auto text-center mb-3">
                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                        {FINANCIAL_DOCUMENTS.map((document, key) => (
                            <Button
                                key={key}
                                color="primary"
                                className={classnames({ active: toSnakeCase(document) === selectedFinancialsDocument })}
                                onClick={() => {
                                    setSelectedFinancialsDocument(toSnakeCase(document))
                                }}
                            >
                                <input type="radio" name="options" id={`option${key}`} autoComplete="off" />
                                {document}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>
                <Col xs={12} md={6} className="mx-auto text-center">
                    <ButtonGroup className="btn-group-toggle" data-toggle="buttons">
                        {FINANCIAL_PERIODS.map((period, key) => (
                            <Button
                                key={key}
                                color="primary"
                                className={classnames({ active: toSnakeCase(period) === selectedFinancialsPeriod })}
                                onClick={() => {
                                    setSelectedFinancialsPeriod(toSnakeCase(period))
                                }}
                            >
                                <input type="radio" name="options" id={`option${key}`} autoComplete="off" />
                                {period}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Col>
            </Row>
            <Row className="mt-5 justify-content-center">
                <FinancialsBarChart
                    chartData={data}
                    currencySymbol={currencySymbol}
                    documentType={selectedFinancialsDocument}
                    height={700}
                    width={1000}
                />
            </Row>
        </Col>
    )
}

export default CompanyFinancials
