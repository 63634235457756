import { useContext } from "react"
import { Button, ButtonGroup } from "reactstrap"
import classnames from "classnames"

import { UserContext } from "components/Contexts/UserContext"

function ViewSwitch({ text1, text2 }) {
    /**
     Toggles the view between personal and editor's picks views.
     This function handles toggling between the personal view and editor's picks
     view for stock information. It uses the UserContext to get the current view
     state and toggle it on button click. Appropriate styling is applied to indicate
     the active view.
     @param {string} text1 - Text for personal view button (default: 'Personal').
     @param {string} text2 - Text for editor's picks button (default: 'Editor's Picks').
     **/

    const { isPersonalPageView, setIsPersonalPageView } = useContext(UserContext)

    const toggleClass = () => {
        setIsPersonalPageView(!isPersonalPageView)
    }

    return (
        <ButtonGroup className="btn-group-toggle mr-3 mt-2 neumorphic" data-toggle="buttons">
            <Button
                className={classnames({ active: isPersonalPageView, border: !isPersonalPageView })}
                color="secondary"
                onClick={toggleClass}
            >
                <input autoComplete="off" name="options" type="radio" value={isPersonalPageView} />
                {text1 || "Personal"}
            </Button>
            <Button
                className={classnames({ active: !isPersonalPageView, border: isPersonalPageView })}
                color="secondary"
                onClick={toggleClass}
            >
                <input autoComplete="off" name="options" type="radio" value={!isPersonalPageView} />
                {text2 || "Editor's Picks"}
            </Button>
        </ButtonGroup>
    )
}

export default ViewSwitch
