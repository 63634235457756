import { useState } from "react"
import { Button, Row } from "reactstrap"

function ExpandableText({ text, maxLength }) {
    const [isActive, setActive] = useState(false)

    const toggleClass = () => {
        setActive(!isActive)
    }

    return (
        <>
            {text.length <= maxLength ? (
                <p style={{ fontSize: "medium" }} className="stat_value">
                    {text}
                </p>
            ) : (
                <>
                    <p style={{ fontSize: "medium" }} className="stat_value">
                        {text.substr(0, maxLength)}
                        {isActive ? <span>{text.substr(maxLength)}</span> : <span>...</span>}
                    </p>
                    <Row>
                        <Button
                            type="button"
                            size="sm"
                            onClick={toggleClass}
                            outline={isActive}
                            className="mx-auto"
                            color="default"
                            style={{ fontSize: "14px" }}
                        >
                            {isActive ? "Show Less" : "Show More"}
                        </Button>
                    </Row>
                </>
            )}
        </>
    )
}

export default ExpandableText
