/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import { useState, useEffect, useContext } from "react"
// reactstrap components
import { Button, Container, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownItem, DropdownMenu } from "reactstrap"
// core components
import { UserContext } from "components/Contexts/UserContext"
import AlternativeHeader from "components/Headers/AlternativeHeader"
import Calendar from "components/Custom/Calendar"
import { API_URL } from "app"
import AuthProvider from "components/Token/AuthProvider"

// eslint-disable-next-line
Date.prototype.addDays = function (n) {
    var date = new Date(this.valueOf())
    date.setDate(date.getDate() + n)
    return date
}

function CalendarView() {
    const [authFetch] = AuthProvider()

    // only use to get date, do not set
    let today = new Date()
    today.setHours(0, 0, 0, 0)
    if (today.getDay() > 5) today = today.addDays(8 - today.getDay())
    else today = today.addDays(1 - today.getDay())

    const [currentDate] = useState(today)
    const [startDate, setStartDate] = useState(currentDate)
    // n = week length - 1 = 5 - 1 = 4
    const [endDate, setEndDate] = useState(today.addDays(4))
    endDate.setHours(23, 59, 59, 999)
    const [maxDate, setMaxDate] = useState(endDate)
    const [calendarType, setCalendarType] = useState("Earnings")

    const [dates, setDates] = useState([])
    const { favoritesMode } = useContext(UserContext)

    useEffect(() => {
        setDates([])
        let url = `${API_URL}/calendar_data`
        let payload = { method: "POST", body: JSON.stringify({ is_favorites: favoritesMode, dates_type: calendarType }) }

        authFetch(url, payload, "FAILED TO LOAD CALENDAR DATA").then((data) => {
            if (data) {
                data = data["calendar_data"]
                for (let key in data) {
                    data[key]["Date"] = new Date(data[key]["Date"])
                    data[key]["Date"].setHours(1, 0, 0)
                }
                // `data &&` is used in case the user is logged out when trying to use this page
                let calculatedMaxDate = data && data.reduce((a, b) => (a["Date"] > b["Date"] ? a : b))["Date"]
                setMaxDate(calculatedMaxDate)
                setDates(data)
            } else setDates([])
        })
        // eslint-disable-next-line
    }, [favoritesMode, calendarType])

    return (
        <>
            <AlternativeHeader parentPage="Earnings Calendar" enableFavoritesSwitch={true} />
            <div className="header header-dark primary-gradient text-bl pb-6 content__title content__title--calendar">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            {/********* Date *********/}
                            <Col md={6} lg={4}>
                                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0 mr-1">
                                    {startDate.toDateString()} - {endDate.toDateString()}
                                </h6>
                            </Col>
                            {/********* Dropdown *********/}
                            <Col xs={6} md={2} className="text-md-left mr-auto">
                                <UncontrolledDropdown>
                                    <DropdownToggle caret color="secondary">
                                        {calendarType}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem
                                            onClick={() => {
                                                setCalendarType("Earnings")
                                            }}
                                        >
                                            Earnings
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                setCalendarType("Ex-Dividend Dates")
                                            }}
                                        >
                                            Ex-Dividend Dates
                                        </DropdownItem>
                                        <DropdownItem
                                            onClick={() => {
                                                setCalendarType("Dividend Payouts")
                                            }}
                                        >
                                            Dividend Payout
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                            {/********* Controls *********/}
                            <Col className="mt-3 mt-md-0 text-md-right ml-auto text-right" xs={6}>
                                <Button
                                    disabled={startDate.getTime() === today.getTime()}
                                    className="fullcalendar-btn-prev btn-neutral"
                                    color="default"
                                    onClick={() => {
                                        setStartDate(startDate.addDays(-7))
                                        setEndDate(endDate.addDays(-7))
                                    }}
                                    size="sm"
                                >
                                    <i className="fas fa-angle-left" />
                                </Button>
                                <Button
                                    disabled={maxDate < endDate}
                                    className="fullcalendar-btn-next btn-neutral"
                                    color="default"
                                    onClick={() => {
                                        setStartDate(startDate.addDays(7))
                                        setEndDate(endDate.addDays(7))
                                    }}
                                    size="sm"
                                >
                                    <i className="fas fa-angle-right" />
                                </Button>
                            </Col>
                        </Row>
                    </div>
                </Container>
            </div>
            <Calendar dates={dates} startDate={startDate} endDate={endDate} />
        </>
    )
}

export default CalendarView
