import { useContext } from "react"
import { Row } from "reactstrap"

import { UserContext } from "components/Contexts/UserContext"

function FavoritesSwitch() {
    const { favoritesMode, setFavoritesMode } = useContext(UserContext)

    const toggleClass = () => {
        setFavoritesMode(!favoritesMode)
    }

    return (
        <>
            <div className="mr-4">
                <div className="mx-auto">
                    <Row className="text-center">Favorites Only</Row>
                    <Row className="mx-auto">
                        <label className="custom-toggle ml-1">
                            <input defaultChecked={favoritesMode} type="checkbox" onChange={toggleClass} />
                            <span
                                className="custom-toggle-slider rounded-circle neumorphic soft-gradient"
                                data-label-off="Off"
                                data-label-on="On"
                            />
                        </label>
                    </Row>
                </div>
            </div>
        </>
    )
}

export default FavoritesSwitch
