/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useContext, useEffect, useState } from "react"
// reactstrap components
import { Container, Row, Col, Button } from "reactstrap"
// core components
import { UserContext } from "components/Contexts/UserContext"
import { MarketMovementModalContext } from "components/Contexts/MarketMovementModalContext"
import triggerNotification from "utils/notifications"
import AuthProvider from "components/Token/AuthProvider"
import AlternativeHeader from "components/Headers/AlternativeHeader.js"
import MarketMovementDashboard from "components/Custom/MarketMovementDashboard"
import DashboardBuilder from "components/Custom/DashboardBuilder"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { API_URL } from "app"

function MarketMovements() {
    const [authFetch] = AuthProvider()
    const { favoritesMode, isPersonalPageView, notifyRef } = useContext(UserContext)
    const { setModalData, setModalMode, setTableIndex } = useContext(MarketMovementModalContext)
    const [dashboardData, setDashboardData] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    // Used to allow triggering data refreshes
    const [dataRefresher, setDataRefresher] = useState(null)

    const refreshDashboardData = () => {
        setDashboardData(null)
        let url = `${API_URL}/performance-dashboard`
        let payload = {
            method: "POST",
            body: JSON.stringify({
                is_favorites: favoritesMode,
                is_personal: isPersonalPageView,
            }),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) setDashboardData(data["data"])
            else setDashboardData([])
        })
    }

    const deleteCard = (tableIndex) => {
        let url = `${API_URL}/delete-card`
        let payload = {
            method: "POST",
            body: JSON.stringify({
                table_index: tableIndex,
            }),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) {
                // delete the element in dashboardData at tableIndex
                let newDashboardData = [...dashboardData]
                newDashboardData.splice(tableIndex, 1)
                setDashboardData(newDashboardData)
                triggerNotification(notifyRef, "Dashboard Card Deleted", "success")
            } else triggerNotification(notifyRef, "Failed To Delete Dashboard Card", "danger")
        })
    }

    const duplicateCard = (tableIndex, source) => {
        let url = `${API_URL}/duplicate-card`
        let payload = {
            method: "POST",
            body: JSON.stringify({
                table_index: tableIndex,
                source: source,
            }),
        }
        authFetch(url, payload, null).then((data) => {
            let message
            if (data) {
                if (source === "user") {
                    message = "Dashboard Card Duplicated"
                    refreshDashboardData()
                } else message = "Editors Picks Card Added To Dashboard"
            } else {
                if (source === "user") message = "Failed To Duplicate Dashboard Card"
                else message = "Failed To Add Editors Picks Card To Dashboard"
            }
            triggerNotification(notifyRef, message, "success")
        })
    }

    const duplicateUserCard = (tableIndex) => {
        duplicateCard(tableIndex, "user")
    }

    const duplicateEditorsPicksCard = (tableIndex) => {
        duplicateCard(tableIndex, "editors_picks")
    }

    const editCard = (tableIndex) => {
        setModalMode("edit")
        setTableIndex(tableIndex)
        setModalData(dashboardData[tableIndex].query)
        setModalOpen(true)
    }

    let generalButtonsConfig = [
        {
            text: "Add To Dashboard",
            action: duplicateEditorsPicksCard,
            color: "primary",
        },
    ]

    let personalButtonsConfig = [
        {
            text: "Edit",
            action: editCard,
            color: "primary",
        },
        {
            text: "Duplicate",
            action: duplicateUserCard,
            color: "primary",
        },
        {
            text: "Delete",
            action: deleteCard,
            color: "danger",
        },
    ]

    useEffect(() => {
        refreshDashboardData()
        // eslint-disable-next-line
    }, [isPersonalPageView, favoritesMode, dataRefresher])

    return (
        <>
            <AlternativeHeader parentPage="Dashboards" childPage="Market Movements" viewSwitchSettings={{}} enableFavoritesSwitch={true} />
            <Container fluid>
                {isPersonalPageView && (
                    <Row>
                        <Col className="text-right mr-5 mb-3">
                            <Button
                                type="button"
                                color="secondary"
                                className="btn-default px-3 py-2"
                                style={{ fontSize: 14 }}
                                onClick={() => setModalOpen(true)}
                            >
                                Add Card
                            </Button>
                        </Col>
                    </Row>
                )}
                <Row>
                    {dashboardData ? (
                        dashboardData.length === 0 ? (
                            <h1 className="mx-auto my-5 text-center">
                                You currently do not have any dashboards saved.
                                <br />
                                Create new ones using the "plus" button above.
                            </h1>
                        ) : (
                            <MarketMovementDashboard
                                dashboardData={dashboardData}
                                buttonsConfig={isPersonalPageView ? personalButtonsConfig : generalButtonsConfig}
                            />
                        )
                    ) : (
                        <LoadingData />
                    )}
                </Row>
            </Container>

            {/******************  Editing Modal   ******************/}
            {isPersonalPageView && <DashboardBuilder modalOpen={modalOpen} setModalOpen={setModalOpen} setDataRefresher={setDataRefresher} />}
        </>
    )
}

export default MarketMovements
