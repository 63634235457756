function handle_http_error(error) {
    console.error(error)
    if (error.response) {
        console.error(error.response)
        console.error(error.response.status)
        console.error(error.response.headers)
    }
}

export default handle_http_error
