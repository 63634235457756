import { createContext, useRef } from "react"
import useLocalStorage from "components/Contexts/UseLocalStorage"

export const UserContext = createContext({
    favoritesMode: true,
    setFavoritesMode: () => null,
    isPersonalPageView: true,
    setIsPersonalPageView: () => null,
    portfolioTickers: [],
    setPortfolioTickers: () => null,
    chartPeriod: "1D",
    setChartPeriod: () => null,
    user: null,
    setUser: () => null,
    accessToken: null,
    setAccessToken: () => null,
    isLoggedIn: false,
    setIsLoggedIn: () => null,
    redirectPath: null,
    setRedirectPath: () => null,
    notifyRef: null,
})

export const UserProvider = ({ children }) => {
    const [isPersonalPageView, setIsPersonalPageView] = useLocalStorage("isPersonalPageView", true)
    const [favoritesMode, setFavoritesMode] = useLocalStorage("favoritesMode", true)
    const [chartPeriod, setChartPeriod] = useLocalStorage("chartPeriod", "1D")
    const [user, setUser] = useLocalStorage("user", {})
    const [accessToken, setAccessToken] = useLocalStorage("accessToken", null)
    const [isLoggedIn, setIsLoggedIn] = useLocalStorage("isLoggedIn", null)
    const [redirectPath, setRedirectPath] = useLocalStorage("redirectPath", null)
    const notifyRef = useRef(null)
    const value = {
        favoritesMode,
        setFavoritesMode,
        isPersonalPageView,
        setIsPersonalPageView,
        chartPeriod,
        setChartPeriod,
        user,
        setUser,
        accessToken,
        setAccessToken,
        isLoggedIn,
        setIsLoggedIn,
        redirectPath,
        setRedirectPath,
        notifyRef,
    }

    return <UserContext.Provider value={value}>{children}</UserContext.Provider>
}
