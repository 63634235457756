import React from "react"
import { Col } from "reactstrap"
import StatTable from "components/Custom/StatTable"

function MarketMovementDashboard({ dashboardData, buttonsConfig }) {
    return (
        <>
            {dashboardData.map((prop, index) => (
                <Col md="6" lg="6" xl="4" key={index}>
                    <StatTable
                        title={prop.title}
                        tableIndex={index}
                        tableData={prop.table}
                        sortingCol={prop.sorting_col}
                        queryData={prop.query}
                        buttonsConfig={buttonsConfig}
                        key={index}
                    />
                </Col>
            ))}
        </>
    )
}

export default MarketMovementDashboard
