import { Card, Col, Container, Row } from "reactstrap"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import CalendarCol from "components/Custom/CalendarCol"

const days = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"]

function Calendar({ dates, startDate, endDate }) {
    return (
        <Container className="mt--6 mb-3" fluid>
            <Row>
                <Col>
                    <Card className="frosted-glass">
                        {dates.length > 0 ? (
                            <div className="table-responsive">
                                <table id="table" className="table">
                                    <thead>
                                        <tr>
                                            {days.map((day, key) => (
                                                <th
                                                    style={{ minWidth: 250, width: `${100 / days.length}%` }}
                                                    className="text-center"
                                                    key={key}
                                                >
                                                    {day}
                                                    <br />
                                                    {startDate.addDays(key).toLocaleDateString("en-AU")}
                                                </th>
                                            ))}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            {days.map((day, key) => {
                                                let dayEvents = dates.filter(
                                                    (elem) =>
                                                        elem["Date"].getDay() === key + 1 &&
                                                        elem["Date"] >= startDate &&
                                                        elem["Date"] <= endDate
                                                )

                                                return (
                                                    <td
                                                        className={"text-center mt-3 mb-3 " + (key < days.length - 1 && "border-right")}
                                                        key={key}
                                                    >
                                                        <Row className="mx-auto justify-content-center">
                                                            <CalendarCol dayEvents={dayEvents} />
                                                        </Row>
                                                    </td>
                                                )
                                            })}
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        ) : (
                            <LoadingData />
                        )}
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default Calendar
