/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { useEffect, useState } from "react"
// reactstrap components
import { Container, Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap"
// core components
import AuthProvider from "components/Token/AuthProvider"
import AlternativeHeader from "components/Headers/AlternativeHeader.js"
import StatTable from "components/Custom/StatTable"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"
import { API_URL } from "app"

function SectorPerformance() {
    const [authFetch] = AuthProvider()
    const [dashboardData, setDashboardData] = useState(null)
    const [index, setIndex] = useState({ name: "S&P 500", value: "sp500" })
    const [parameter, setParameter] = useState({
        name: "Yesterday Open Close Variance",
        value: "yesterday_open_close_variance",
    })

    const refreshDashboardData = () => {
        setDashboardData(null)
        let url = `${API_URL}/sector-dashboard`
        let payload = {
            method: "POST",
            body: JSON.stringify({
                index: index.value,
                parameter: parameter.value,
            }),
        }
        authFetch(url, payload, null).then((data) => {
            if (data) setDashboardData(data["data"])
            else setDashboardData([])
        })
    }

    const indexes = [
        {
            name: "S&P 500",
            value: "sp500",
        },
        {
            name: "NASDAQ 100",
            value: "nasdaq100",
        },
        {
            name: "Dow Jones",
            value: "dowjones",
        },
        {
            name: "STOXX 50",
            value: "stoxx50",
        },
        {
            name: "FTSE 100",
            value: "gb100",
        },
        {
            name: "FR 40",
            value: "fr40",
        },
        {
            name: "AU 200",
            value: "au200",
        },
    ]

    const parameters = [
        {
            name: "Yesterday Open Close Variance",
            value: "yesterday_open_close_variance",
        },
        {
            name: "Week Ago Open Close Variance",
            value: "week_ago_open_close_variance",
        },
        {
            name: "Month Ago Open Close Variance",
            value: "month_ago_open_close_variance",
        },
        {
            name: "Three Months Ago Open Close Variance",
            value: "three_months_ago_open_close_variance",
        },
        {
            name: "Six Months Ago Open Close Variance",
            value: "six_months_ago_open_close_variance",
        },
        {
            name: "Year Ago Open Close Variance",
            value: "year_ago_open_close_variance",
        },
        {
            name: "Market Cap",
            value: "market_cap",
        },
        {
            name: "Weight",
            value: "weight",
        },
    ]

    useEffect(() => {
        refreshDashboardData()
        // eslint-disable-next-line
    }, [index, parameter])

    return (
        <>
            <AlternativeHeader parentPage="Dashboards" childPage="Sector Performance" />
            <Container fluid>
                {/***************  Filter Controls   ***************/}
                <Row>
                    <Col md={12} lg={8} className="mx-auto">
                        <Row className="text-center">
                            {/***************  Index Filter   *****************/}
                            <Col sm={12} md={6} className="mb-3 text-left text-md-center">
                                <span className="font-weight-bold mr-2">Index: </span>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret color="secondary">
                                        {index.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {indexes.map((prop, key) => {
                                            return (
                                                <DropdownItem href="#pablo" onClick={() => setIndex(prop)} key={key + 1}>
                                                    {prop.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                            {/***************  Parameter Filter   *****************/}
                            <Col sm={12} md={6} className="mb-3 text-left text-md-center">
                                <span className="font-weight-bold mr-2">Value: </span>
                                <UncontrolledDropdown>
                                    <DropdownToggle caret color="secondary">
                                        {parameter.name}
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        {parameters.map((prop, key) => {
                                            return (
                                                <DropdownItem href="#pablo" onClick={() => setParameter(prop)} key={key + 1}>
                                                    {prop.name}
                                                </DropdownItem>
                                            )
                                        })}
                                    </DropdownMenu>
                                </UncontrolledDropdown>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/***************  Dashboard Data   ***************/}
                <Row>
                    {dashboardData ? (
                        dashboardData.map((data, index) => (
                            <Col md="6" lg="6" xl="4" key={index}>
                                <StatTable
                                    title={data.title}
                                    tableIndex={index}
                                    tableData={data.table}
                                    sortingCol={data.sorting_col}
                                    key={index}
                                />
                            </Col>
                        ))
                    ) : (
                        <LoadingData />
                    )}
                </Row>
            </Container>
        </>
    )
}

export default SectorPerformance
