import { Col, Row, Card, CardBody } from "reactstrap"
import CompanyFigure from "components/Custom/CompanyFigure"
import StockPriceValues from "components/Custom/StockPriceValues"
import React from "react"

export default function StockPriceCard({ logoURL, ticker, name, price, priceChange, marketState }) {
    if (name.length > 32) name = `${name.substring(0, 32)}...`
    return (
        <Card className="card-stats blur--hover" style={{ maxHeight: 100 }}>
            <CardBody className="my--2">
                <Row>
                    <Col className="pl-1">
                        <h6 className="heading-small text-muted" style={{ lineHeight: 1.23 }}>
                            {name}
                        </h6>
                        <div className="position-absolute bottom-0">
                            <StockPriceValues price={price} change={priceChange} marketState={marketState} />
                        </div>
                    </Col>
                    <Col className="col-auto p-0">
                        <CompanyFigure
                            neumorphic={true}
                            zoom={true}
                            width={64}
                            height={64}
                            logoURL={logoURL}
                            ticker={ticker}
                            alt={ticker}
                            hideTicker={false}
                        />
                    </Col>
                </Row>
            </CardBody>
        </Card>
    )
}
