import CompanyFigure from "../CompanyFigure"
import { Card, CardBody, Col, Row } from "reactstrap"

function StocksRecommendation({ suggestions }) {
    return (
        <Row>
            <Col className="px-2 mx-auto" style={{ maxWidth: 700 }}>
                <Card className="card-stats bg-gradient-gray">
                    <CardBody className="pb-1">
                        <Row>
                            <div className="col mb-3">
                                <span className="h1 font-weight-bold" style={{ color: "white" }}>
                                    Similar Stocks
                                </span>
                            </div>
                        </Row>
                        <Row className="justify-content-center">
                            {suggestions.map(({ ticker, logo_url }) => (
                                <CompanyFigure
                                    autoSize={true}
                                    ticker={ticker}
                                    zoom={true}
                                    textStyle={{ color: "white" }}
                                    logoURL={logo_url}
                                    extraClasses={"mx-1"}
                                    key={ticker}
                                />
                            ))}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )
}

export default StocksRecommendation
