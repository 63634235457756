import Lottie from "react-lottie-player"

import lottieJson from "assets/lottie/Loading Atom.json"

function SmartLoadingData({ width, height }) {
    return (
        <Lottie className="mx-auto" animationData={lottieJson} speed={1.5} style={{ width: width || 300, height: height || 300 }} loop play />
    )
}

export default SmartLoadingData
