/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// reactstrap components
import { Breadcrumb, BreadcrumbItem, Container, Row, Col } from "reactstrap"

import FavoritesSwitch from "components/Custom/FavoritesSwitch"
import ViewSwitch from "components/Custom/ViewSwitch"

const style = {
    fontSize: "20px",
}

function AlternativeHeader({ parentPage, childPage, viewSwitchSettings, enableFavoritesSwitch }) {
    return (
        <>
            <div className="header">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center py-4">
                            {/* Breadcrumb */}
                            <Col lg="6" md="6" sm="12">
                                <Breadcrumb className="d-none d-md-inline-block ml-md-4" listClassName="breadcrumb-links">
                                    <BreadcrumbItem style={style}>
                                        <a href="/">
                                            <i className="fas fa-home" />
                                        </a>
                                    </BreadcrumbItem>
                                    <BreadcrumbItem style={style}>
                                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                            {parentPage}
                                        </a>
                                    </BreadcrumbItem>
                                    {childPage ? (
                                        <BreadcrumbItem aria-current="page" className="active" style={style}>
                                            {childPage}
                                        </BreadcrumbItem>
                                    ) : (
                                        ""
                                    )}
                                </Breadcrumb>
                            </Col>
                            {/* Page Controls */}
                            {(viewSwitchSettings || enableFavoritesSwitch) && (
                                <Col className="text-right" lg="6" md="6" xs="12">
                                    <Row className="float-right">
                                        {enableFavoritesSwitch && <FavoritesSwitch />}
                                        {viewSwitchSettings && (
                                            <ViewSwitch text1={viewSwitchSettings.text1} text2={viewSwitchSettings.text2} />
                                        )}
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default AlternativeHeader
