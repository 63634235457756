/*!

=========================================================
* Argon Dashboard PRO React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// nodejs library that concatenates classes
import classnames from "classnames"
// nodejs library to set properties for components
import PropTypes from "prop-types"
// reactstrap components
import {
    Collapse,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    DropdownToggle,
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    ListGroupItem,
    ListGroup,
    Media,
    Navbar,
    NavItem,
    NavLink,
    Nav,
    Container,
    Row,
    Col,
} from "reactstrap"
import { createAvatar } from "@dicebear/core"
import { micah } from "@dicebear/collection"
import { useHistory } from "react-router-dom"
import { useContext, useEffect, useState, useMemo } from "react"
import AuthProvider from "components/Token/AuthProvider"
import CompanyImage from "components/Custom/CompanyImage"
import { UserContext } from "components/Contexts/UserContext"
import { API_URL } from "app"
import LoadingData from "components/Custom/LottieAnimations/LoadingData"

function AdminNavbar({ theme, sidenavOpen, toggleSidenav }) {
    const { user, setAccessToken, setIsLoggedIn } = useContext(UserContext)
    const [authFetch, abort] = AuthProvider()
    const [searchSuggestions, setSearchSuggestions] = useState(null)
    const [searchText, setSearchText] = useState("")
    const history = useHistory()

    const fullName = `${user.first_name} ${user.last_name}`

    const avatar = useMemo(() => {
        return createAvatar(micah, {
            seed: user.email,
            size: 128,
        }).toDataUriSync()
    }, [user])

    var typingTimer
    const doneTypingInterval = 230

    // function that on mobile devices makes the search open
    const openSearch = () => {
        document.body.classList.add("g-navbar-search-showing")
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-showing")
            document.body.classList.add("g-navbar-search-show")
        }, 150)
        setTimeout(function () {
            document.body.classList.add("g-navbar-search-shown")
        }, 300)
    }
    // function that on mobile devices makes the search close
    const closeSearch = () => {
        document.body.classList.remove("g-navbar-search-shown")
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-show")
            document.body.classList.add("g-navbar-search-hiding")
        }, 150)
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hiding")
            document.body.classList.add("g-navbar-search-hidden")
        }, 300)
        setTimeout(function () {
            document.body.classList.remove("g-navbar-search-hidden")
        }, 500)
    }

    useEffect(() => {
        if (searchText) {
            setSearchSuggestions(null)
            let url = `${API_URL}/autocomplete`
            let payload = { method: "POST", body: JSON.stringify({ query: searchText }) }
            authFetch(url, payload, null).then((data) => {
                if (data) setSearchSuggestions(data["suggestions"])
                else setSearchSuggestions([])
            })
        } else setSearchSuggestions([])
        // eslint-disable-next-line
    }, [searchText])

    const renderSuggestionResults = () =>
        searchSuggestions.map(({ ticker, name, logo_url }, key) => (
            <DropdownItem key={key} href={`/stock/${ticker}`}>
                <Row>
                    <Col xs={2}>
                        <CompanyImage width={42} height={42} alt={ticker} logoURL={logo_url} neumorphic={true} />
                    </Col>
                    <Col className="mr-auto text-left pt-2 pl-1">
                        <span style={{ fontWeight: 600 }}>{ticker}</span> - {name.length > 30 ? `${name.substring(0, 30)}...` : name}
                    </Col>
                </Row>
            </DropdownItem>
        ))

    const renderSuggestions = () => {
        if (searchText) {
            let content
            if (!searchSuggestions) content = <LoadingData />
            else if (searchSuggestions.length === 0)
                content = (
                    <div className="m-3">
                        <h3>Oops nothing found...</h3>
                    </div>
                )
            else content = renderSuggestionResults()
            return <DropdownMenu style={{ width: Math.min(400, window.innerWidth - 30) }}>{content}</DropdownMenu>
        }
    }

    const logOut = () => {
        history.push("/login")
        setIsLoggedIn(false)
        setAccessToken(null)
    }

    return (
        <>
            <Navbar
                className={classnames(
                    "navbar-top navbar-expand border-bottom",
                    { "navbar-dark bg-info": theme === "dark" },
                    { "navbar-light bg-secondary": theme === "light" }
                )}
                style={{ zIndex: 999 }}
            >
                <Container fluid>
                    <Collapse navbar isOpen={true}>
                        <Form
                            className={classnames(
                                "navbar-search form-inline mr-sm-3",
                                { "navbar-search-light": theme === "dark" },
                                { "navbar-search-dark": theme === "light" }
                            )}
                        >
                            <UncontrolledDropdown style={{ width: Math.min(420, window.innerWidth - 30) }}>
                                <FormGroup className="mb-0">
                                    <DropdownToggle tag={"div"}>
                                        <InputGroup className="input-group-alternative input-group-merge">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            {/* disable submitting when hitting enter*/}
                                            <Input
                                                placeholder="Search"
                                                type="search"
                                                autoComplete="false"
                                                style={{ fontSize: 16 }}
                                                onKeyDown={(e) => {
                                                    e.key === "Enter" && e.preventDefault()
                                                    clearTimeout(typingTimer)
                                                    abort()
                                                }}
                                                onKeyUp={(event) => {
                                                    clearTimeout(typingTimer)
                                                    typingTimer = setTimeout(() => setSearchText(event.target.value), doneTypingInterval)
                                                }}
                                            />
                                        </InputGroup>
                                    </DropdownToggle>
                                    {renderSuggestions()}
                                </FormGroup>
                            </UncontrolledDropdown>
                            <button aria-label="Close" className="close" type="button" onClick={closeSearch}>
                                <span aria-hidden={true}>×</span>
                            </button>
                        </Form>

                        <Nav className="align-items-center ml-md-auto" navbar>
                            <NavItem className="d-xl-none">
                                <div
                                    className={classnames(
                                        "pr-3 sidenav-toggler",
                                        { active: sidenavOpen },
                                        { "sidenav-toggler-dark": theme === "dark" }
                                    )}
                                    onClick={toggleSidenav}
                                >
                                    <div className="sidenav-toggler-inner">
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                        <i className="sidenav-toggler-line" />
                                    </div>
                                </div>
                            </NavItem>
                            <NavItem className="d-sm-none">
                                <NavLink onClick={openSearch}>
                                    <i className="ni ni-zoom-split-in" />
                                </NavLink>
                            </NavItem>
                            {/***** Notifications *****/}
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="ni ni-bell-55" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-xl py-0 overflow-hidden frosted-glass" right>
                                    <div className="px-3 py-3">
                                        <h6 className="text-sm text-muted m-0">
                                            You have <strong className="text-info">13</strong> notifications.
                                        </h6>
                                    </div>

                                    <ListGroup flush>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={createAvatar(micah, { seed: 1, size: 128 }).toDataUriSync()}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>2 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={createAvatar(micah, { seed: 2, size: 128 }).toDataUriSync()}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>3 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={createAvatar(micah, { seed: 3, size: 128 }).toDataUriSync()}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>5 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">Your posts have been liked a lot.</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={createAvatar(micah, { seed: 4, size: 128 }).toDataUriSync()}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>2 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">Let's meet at Starbucks at 11:30. Wdyt?</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                        <ListGroupItem
                                            className="list-group-item-action"
                                            href="#pablo"
                                            onClick={(e) => e.preventDefault()}
                                            tag="a"
                                        >
                                            <Row className="align-items-center">
                                                <Col className="col-auto">
                                                    <img
                                                        alt="..."
                                                        className="avatar rounded-circle"
                                                        src={createAvatar(micah, { seed: 5, size: 128 }).toDataUriSync()}
                                                    />
                                                </Col>
                                                <div className="col ml--2">
                                                    <div className="d-flex justify-content-between align-items-center">
                                                        <div>
                                                            <h4 className="mb-0 text-sm">John Snow</h4>
                                                        </div>
                                                        <div className="text-right text-muted">
                                                            <small>3 hrs ago</small>
                                                        </div>
                                                    </div>
                                                    <p className="text-sm mb-0">A new issue has been reported for Argon.</p>
                                                </div>
                                            </Row>
                                        </ListGroupItem>
                                    </ListGroup>

                                    <DropdownItem
                                        className="text-center text-info font-weight-bold py-3"
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        View all
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                            {/***** Action Buttons *****/}
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link" color="" tag="a">
                                    <i className="ni ni-ungroup" />
                                </DropdownToggle>
                                <DropdownMenu className="dropdown-menu-lg frosted-glass" right>
                                    <Row className="shortcuts px-4 text-default">
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-red">
                                                <i className="ni ni-calendar-grid-58" />
                                            </span>
                                            <small className="text-default">Calendar</small>
                                        </Col>
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-orange">
                                                <i className="ni ni-email-83" />
                                            </span>
                                            <small className="text-default">Email</small>
                                        </Col>
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-info">
                                                <i className="ni ni-credit-card" />
                                            </span>
                                            <small className="text-default">Payments</small>
                                        </Col>
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-green">
                                                <i className="ni ni-books" />
                                            </span>
                                            <small className="text-default">Reports</small>
                                        </Col>
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-purple">
                                                <i className="ni ni-pin-3" />
                                            </span>
                                            <small className="text-default">Maps</small>
                                        </Col>
                                        <Col className="shortcut-item" href="#pablo" onClick={(e) => e.preventDefault()} xs="4" tag="a">
                                            <span className="shortcut-media avatar rounded-circle bg-gradient-yellow">
                                                <i className="ni ni-basket" />
                                            </span>
                                            <small className="text-default">Shop</small>
                                        </Col>
                                    </Row>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                        <Nav className="align-items-center ml-auto ml-md-0" navbar style={{ cursor: "pointer" }}>
                            <UncontrolledDropdown nav>
                                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                                    <Media className="align-items-center">
                                        <span className="avatar avatar-sm rounded-circle">
                                            <img src={avatar} alt="Avatar" />
                                        </span>
                                        <Media className="ml-2 d-none d-lg-block">
                                            <span className="mb-0 text-sm font-weight-bold">{fullName}</span>
                                        </Media>
                                    </Media>
                                </DropdownToggle>
                                <DropdownMenu right className="frosted-glass">
                                    <DropdownItem className="noti-title" header tag="div">
                                        <h6 className="text-overflow m-0">Welcome!</h6>
                                    </DropdownItem>
                                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <i className="ni ni-single-02" />
                                        <span>My profile</span>
                                    </DropdownItem>
                                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <i className="ni ni-settings-gear-65" />
                                        <span>Settings</span>
                                    </DropdownItem>
                                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <i className="ni ni-calendar-grid-58" />
                                        <span>Activity</span>
                                    </DropdownItem>
                                    <DropdownItem href="#pablo" onClick={(e) => e.preventDefault()}>
                                        <i className="ni ni-support-16" />
                                        <span>Support</span>
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    <DropdownItem onClick={logOut}>
                                        <i className="ni ni-user-run" />
                                        <span>Logout</span>
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav>
                    </Collapse>
                </Container>
            </Navbar>
        </>
    )
}

AdminNavbar.defaultProps = {
    toggleSidenav: () => {},
    sidenavOpen: false,
    theme: "dark",
}

AdminNavbar.propTypes = {
    toggleSidenav: PropTypes.func,
    sidenavOpen: PropTypes.bool,
    theme: PropTypes.oneOf(["dark", "light"]),
}

export default AdminNavbar
